import React, { useState } from 'react';

const projectImages = [];
projectImages[0] = require('../../assets/images/projects/grab-merchant.jpg');
projectImages[3] = require('../../assets/images/projects/emji-coffee-bar.jpg');
projectImages[4] = require('../../assets/images/projects/tofico.jpg');
projectImages[1] = require('../../assets/images/projects/shopeepay.jpg');
projectImages[5] = require('../../assets/images/projects/skyline-parking.jpg');
projectImages[2] = require('../../assets/images/projects/prima-freshmart.jpg');

export default function ProjectSection() {
  const projects = [
    {
      name: 'Grab Indonesia',
      description: 'GrabFood Merchant Devices',
      category: 'device',
      background: projectImages[0],
    },
    {
      name: 'EMJI Coffee & Bar',
      description: 'Point of Sales Application',
      category: 'system',
      background: projectImages[3],
    },
    {
      name: 'TOFICO',
      description: 'Warehouse System & Application',
      category: 'system',
      background: projectImages[4],
    },
    {
      name: 'Shopee',
      description: 'ShopeePay Payment Terminal',
      category: 'device',
      background: projectImages[1],
    },
    {
      name: 'Skyline Parking',
      description: 'On-Street Parking Solution',
      category: 'system',
      background: projectImages[5],
    },
    {
      name: 'Prima Freshmart',
      description: 'Mobile Point of Sales Terminal',
      category: 'device',
      background: projectImages[2],
    },
  ];
  const [filter, setFilter] = useState(null);

  return (
    <>
      <section id="project" className="bg-white">
        <div className="mx-auto max-w-6xl px-6 py-20">
          <div className="px-12 lg:px-32">
            <h4 className="mt-4 text-4xl text-center font-bold leading-10" data-aos="fade-up">
              Our Awesome Project
            </h4>
            <p className="mt-4 text-lg text-center" data-aos="fade-up" data-aos-delay="100">
              Take a look at some of our awesome project, you can learn from them about what we can
              provide to satisfy our customer. We already form a partnership with any kind of
              business, from small to large company
            </p>
          </div>

          <div className="mt-12 flex justify-center gap-x-1">
            <button
              type="button"
              className={`py-2 px-5 ${
                filter ? '' : 'bg-sky-400 text-white'
              } hover:bg-sky-400 hover:text-white font-bold rounded-full ease-linear transition-all duration-150`}
              onClick={() => setFilter(null)}>
              ALL
            </button>
            <button
              type="button"
              className={`py-2 px-5 ${
                filter === 'device' ? 'bg-sky-400 text-white' : ''
              } hover:bg-sky-400 hover:text-white font-bold rounded-full ease-linear transition-all duration-150`}
              onClick={() => setFilter('device')}>
              DEVICE
            </button>
            <button
              type="button"
              className={`py-2 px-5 ${
                filter === 'system' ? 'bg-sky-400 text-white' : ''
              } hover:bg-sky-400 hover:text-white font-bold rounded-full ease-linear transition-all duration-150`}
              onClick={() => setFilter('system')}>
              SYSTEM
            </button>
          </div>

          <div className="mt-12 grid gap-6 grid-cols-1 grid-rows-6 md:grid-cols-2 md:grid-rows-3 lg:grid-cols-3 lg:grid-rows-2">
            {projects
              .filter((project) => filter === null || project.category === filter)
              .map((project, index) => (
                <div key={project.name} data-aos="fade-up" data-aos-delay={index * 100}>
                  <div
                    className="group h-60 p-4 bg-center bg-cover bg-no-repeat flex items-end"
                    style={{
                      backgroundImage: `url(${project.background})`,
                    }}>
                    <div
                      className="h-16 w-full px-4 -mb-4 group-hover:mb-0
                      opacity-0 group-hover:opacity-100 flex flex-col justify-center
                      bg-white/90 ease-linear transition-all duration-150">
                      <h4 className="text-xl font-bold">{project.name}</h4>
                      <div className="-mt-1">{project.description}</div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}
