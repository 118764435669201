import React from 'react';
import AliceCarousel from 'react-alice-carousel';

import { CLIENTS } from '../../constants/data';

export default function ClientSection() {
  const handleDragStart = (e) => e.preventDefault();

  const clientItems = CLIENTS.map((client) => (
    <div key={client.name} className="h-28 px-4 py-2 flex items-center justify-center">
      <img
        src={client.image}
        className="max-h-full max-w-full object-contain grayscale hover:grayscale-0 opacity-50 hover:opacity-100 ease-linear transition-all duration-150"
        onDragStart={handleDragStart}
        alt={client.name}
      />
    </div>
  ));

  return (
    <>
      <section className="bg-white">
        <div className="mx-auto max-w-6xl px-6 py-10">
          <AliceCarousel
            autoPlay
            autoPlayInterval={2000}
            disableButtonsControls
            infinite
            mouseTracking
            items={clientItems}
            responsive={{
              0: { items: 2 },
              640: { items: 4 },
              1024: { items: 6 },
            }}
          />
        </div>
      </section>
    </>
  );
}
