import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { APP_URL, APP_NAME } from '../../constants/env';
import { PRODUCT_BRANDS } from '../../constants/data';

const logo = require('../../assets/images/solusi-bersama-informatika.png');
const clientLogo01 = require('../../assets/images/clients/grab.png');
const clientLogo02 = require('../../assets/images/clients/shopee.png');
const clientLogo03 = require('../../assets/images/clients/prima-freshmart.png');
const clientLogo04 = require('../../assets/images/clients/charoen-pokphand.png');
const clientLogo05 = require('../../assets/images/clients/indogrosir.png');
const clientLogo06 = require('../../assets/images/clients/jet.png');
const clientLogo07 = require('../../assets/images/clients/yuasa.png');
const clientLogo08 = require('../../assets/images/clients/hoka-hoka-bento.png');
const clientLogo09 = require('../../assets/images/clients/lazada-elogistics.png');
const clientLogo10 = require('../../assets/images/clients/ninja-xpress.png');

export default function DevicePromotion() {
  const location = useLocation();

  const whatsappLink =
    'https://wa.me/6282261500700?text=Halo,%20Saya%20tertarik%20dengan%20program%20cicilan%20dari%20SBI';
  const clients = [
    { name: 'Grab', image: clientLogo01 },
    { name: 'Shopee', image: clientLogo02 },
    { name: 'Prima Freshmart', image: clientLogo03 },
    { name: 'PT Chareon Pokphand Indonesia', image: clientLogo04 },
    { name: 'Indogrosir', image: clientLogo05 },
    { name: 'J&T Express Indonesia', image: clientLogo06 },
    { name: 'Yuasa Battery', image: clientLogo07 },
    { name: 'PT Eka Bogainti - HokBen', image: clientLogo08 },
    { name: 'Lazada ELogistics', image: clientLogo09 },
    { name: 'Ninja Xpress', image: clientLogo10 },
  ];

  return (
    <>
      <Helmet>
        <link rel="canonical" href={APP_URL} />
        <title>Promosi Spesial Mesin Kasir Cicilan hingga 12 Bulan | {APP_NAME}</title>
        <meta
          name="description"
          content="Harga spesial mesin kasir untuk kamu yang melakukan pembelian via whatsapp. Dapatkan juga Cicilan hingga 12 Bulan untuk pembelian mesin kasir tipe apapun"
        />
        <meta
          name="keywords"
          content="solusi bersama informatika, sbi, grab, grabmerchant, mesin kasir, mesin kasir android, sunmi, sunmi p2, android point of sales, android pos, aplikasi kasir, sistem kasir, sistem pos"
        />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={APP_NAME} />
        <meta property="og:locale" content="id_ID" />
        <meta
          property="og:title"
          content={`Promosi Spesial Mesin Kasir Cicilan hingga 12 Bulan | ${APP_NAME}`}
        />
        <meta
          property="og:description"
          content="Harga spesial mesin kasir untuk kamu yang melakukan pembelian via whatsapp. Dapatkan juga Cicilan hingga 12 Bulan untuk pembelian mesin kasir tipe apapun"
        />
        <meta
          property="og:image"
          content="https://file.cdn.sunmi.com/newebsite/products/p2/xl/page1.jpg"
        />
        <meta property="og:image:alt" content={`SUNMI - ${APP_NAME}`} />
        <meta property="og:url" content={`${APP_URL}${location.pathname}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`Promosi Spesial Mesin Kasir Cicilan hingga 12 Bulan | ${APP_NAME}`}
        />
        <meta
          name="twitter:description"
          content="Harga spesial mesin kasir untuk kamu yang melakukan pembelian via whatsapp. Dapatkan juga Cicilan hingga 12 Bulan untuk pembelian mesin kasir tipe apapun"
        />
        <meta
          name="twitter:image"
          content="https://file.cdn.sunmi.com/newebsite/products/p2/xl/page1.jpg"
        />
        <meta name="twitter:image:alt" content={`SUNMI - ${APP_NAME}`} />
      </Helmet>

      <section className="relative w-screen h-screen">
        <video
          className="w-full h-full object-cover"
          playsInline
          autoPlay
          muted
          loop
          src="https://file.cdn.sunmi.com/newebsite/products/p2/videos/page2-bg.mp4?autoPlay=true&amp;loop=true"></video>
        <div className="absolute inset-0 flex items-center justify-center px-10 bg-black bg-opacity-40">
          <h1 className="text-center text-white text-5xl md:text-7xl font-light">
            <div>Mulai Bisnis Anda</div>
            <div className="mt-4">
              dengan <span className="font-bold">Mesin Kasir</span> terpercaya
            </div>
            <div className="mt-4">
              <span className="font-bold"> tanpa Modal</span> yang Besar
            </div>
          </h1>
        </div>
      </section>

      <section
        className="relative w-full bg-cover bg-no-repeat bg-center"
        style={{
          backgroundImage: 'url(https://file.cdn.sunmi.com/newebsite/products/list/xl/1.jpg)',
        }}>
        <div className="bg-black bg-opacity-30 px-10 md:px-40 py-20">
          <h3 className="text-center text-white text-4xl md:text-6xl font-light">
            <span className="font-bold">Best Quality</span> with
            <span className="font-bold"> Affordable Price</span>
          </h3>
          <h3 className="mt-4 text-center text-white text-xl md:text-3xl font-light">
            Pilih device yang sesuai kebutuhan Bisnis Kamu
          </h3>

          <div className="mt-20 text-center">
            <a href={whatsappLink} target="_blank" rel="noreferrer">
              <button
                className="py-2 px-6 rounded-full inline-flex items-center text-white font-medium text-xl"
                style={{ backgroundColor: '#25D366' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  style={{ fill: '#000000' }}>
                  <path
                    fill="#fff"
                    d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"></path>
                  <path
                    fill="#fff"
                    d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"></path>
                  <path
                    fill="#cfd8dc"
                    d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"></path>
                  <path
                    fill="#40c351"
                    d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"></path>
                  <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                    clipRule="evenodd"></path>
                </svg>
                <span>&nbsp;Pesan Sekarang</span>
              </button>
            </a>
          </div>
        </div>
      </section>

      <section className="w-full px-10 md:px-40 py-14 bg-white">
        <h4 className="text-center text-slate-700 text-lg md:text-2xl">
          Device kami telah dipercaya banyak <span className="font-bold">Perusahaan Besar</span> di
          Indonesia
        </h4>
        <div className="mt-2 md:mt-4 text-center">
          {clients.map((client) => (
            <img
              key={client.name}
              className="inline-block mx-4 my-2 w-32 md:w-44 h-12 md:h-16 object-contain"
              src={client.image}
              alt={client.name}
            />
          ))}
        </div>
      </section>

      <section>
        <div
          className="w-full bg-cover bg-no-repeat bg-center"
          style={{
            backgroundImage: 'url(https://file.cdn.sunmi.com/newebsite/products/p2/xl/page1.jpg)',
          }}>
          <div className="bg-black bg-opacity-30 px-8 md:px-32 pt-14 md:pt-24 pb-60 md:pb-72">
            <h3 className="text-white text-4xl font-bold">Smart Mobile Terminal</h3>
            <h4 className="mt-4 md:w-1/2 text-white text-lg md:text-xl font-light">
              Mobile Terminal untuk Mesin Kasir atau Alat Pembayaran yang portable. Cocok untuk Kamu
              yang memiliki usaha kecil menengah dengan luas outlet yang terbatas.
            </h4>
          </div>
        </div>

        <div
          className="w-full bg-cover bg-no-repeat bg-center"
          style={{
            backgroundImage:
              'url(https://imin.co.id/wp-content/uploads/2021/09/v1-09265-1-1-4.png)',
          }}>
          <div
            className="flex flex-col items-end bg-black bg-opacity-30 px-8 md:px-32 pt-14 md:pt-24 pb-60 md:pb-72"
            style={{ backgroundImage: 'linear-gradient(to right, transparent, #121212)' }}>
            <h3 className="text-white text-4xl font-bold text-right">Desktop Terminal</h3>
            <h4 className="mt-4 md:w-1/2 text-white text-lg md:text-xl font-light text-right">
              Desktop Terminal untuk Mesin Kasir yang akan membuat outlet Kamu menjadi lebih Keren
              dan Terpercaya. Cocok untuk Kamu yang memiliki usaha menengah keatas seperti
              restaurant, cafe, retail, dsb.
            </h4>
          </div>
        </div>

        <div
          className="w-full bg-cover bg-no-repeat bg-center bg-black"
          style={{
            backgroundImage:
              'url(https://www.idataglobal.com/Uploads/ueditor/image/20210629/6376052879099810281975168.png)',
          }}>
          <div className="bg-black bg-opacity-30 px-8 md:px-32 pt-14 md:pt-24 pb-60 md:pb-72">
            <h3 className="text-white text-4xl font-bold">Barcode Scanner</h3>
            <h4 className="mt-4 md:w-1/2 text-white text-lg md:text-xl font-light">
              Android-based Terminal yang dilengkapi dengan Barcode Scanner infrared. Cocok untuk
              melengkapi usaha Kamu yang memiliki jumlah produk yang besar, dan akan mempermudah
              stock opname. Juga cocok untuk usaha di bidang Logistics.
            </h4>
          </div>
        </div>
      </section>

      <section className="w-full bg-slate-100">
        <div className="px-10 md:px-40 py-10 md:py-20">
          <h3 className="text-center text-slate-600 text-3xl md:text-5xl font-light leading-tight">
            Dapatkan <span className="font-bold">Harga Spesial</span> khusus
            <br />
            pembelian <span className="font-bold">via Whatsapp</span>
          </h3>
          <h3 className="mt-16 text-center text-red-600 text-3xl md:text-5xl font-light">
            dan <span className="font-bold">Cicilan </span>
            mulai dari <span className="font-bold">Rp 11.000an / bulan</span>
          </h3>

          <div className="mt-20 text-center">
            <a href={whatsappLink} target="_blank" rel="noreferrer">
              <button
                className="py-2 px-6 rounded-full inline-flex items-center text-white font-medium text-md md:text-xl"
                style={{ backgroundColor: '#25D366' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  style={{ fill: '#000000' }}>
                  <path
                    fill="#fff"
                    d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"></path>
                  <path
                    fill="#fff"
                    d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"></path>
                  <path
                    fill="#cfd8dc"
                    d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"></path>
                  <path
                    fill="#40c351"
                    d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"></path>
                  <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                    clipRule="evenodd"></path>
                </svg>
                <span>&nbsp;Ambil Promo Sekarang</span>
              </button>
            </a>
          </div>
        </div>
      </section>

      <section className="w-full px-10 md:px-40 py-14 bg-white">
        <div className="flex justify-center">
          <img className="w-60 h-20 object-contain" src={logo} alt="SOLUSI BERSAMA INFORMATIKA" />
        </div>
        <div className="mt-8 md:mt-14">
          <h4 className="text-center text-slate-700 text-sm md:text-xl">
            We're an <span className="font-bold">Authorized Partner</span> of
          </h4>
          <div className="mt-2 md:mt-4 text-center">
            {Object.values(PRODUCT_BRANDS).map((brand) => (
              <img
                key={brand.name}
                className="inline-block mx-4 w-20 md:w-32 h-10 md:h-14 object-contain"
                src={brand.image}
                alt={brand.name}
              />
            ))}
          </div>
        </div>
      </section>

      <footer>
        <div className="bg-slate-800 py-2 text-center text-white text-xs md:text-sm leading-tight">
          &copy;{new Date().getFullYear()} Copyright <strong>PT Solusi Bersama Informatika</strong>.
          All Rights Reserved.
        </div>
      </footer>
    </>
  );
}
