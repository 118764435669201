import React from 'react';
import { Link } from 'react-router-dom';

import { PRODUCT_CATEGORIES, PRODUCT_BRANDS } from '../../constants/data';

export default function ProductSection() {
  return (
    <>
      <section className="bg-slate-100">
        <div className="mx-auto max-w-6xl px-6 py-20">
          <div className="px-12 lg:px-32">
            <h4 className="mt-4 text-4xl text-center font-bold leading-10" data-aos="fade-up">
              Products & Services
            </h4>
            <p className="mt-4 text-lg text-center" data-aos="fade-up" data-aos-delay="100">
              We can provide any smart device and system that can make your business more efficient
              and elegant, and that will make your business looks more trustworthy in the viewpoint
              of your customer
            </p>
          </div>

          <div className="mt-12 grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {Object.values(PRODUCT_CATEGORIES).map((category) => (
              <Link
                key={category.slug}
                to={category.type === 'product' ? `/product/${category.slug}` : '#'}
                data-aos="fade-up"
                data-aos-delay="200">
                <div
                  className="group h-60 p-4 bg-center bg-cover bg-no-repeat flex items-end rounded-lg"
                  style={{
                    backgroundImage: `url(${category.background})`,
                  }}>
                  <div className="h-14 w-full px-4 bg-white/90 group-hover:bg-gradient-to-br from-sky-500 to-indigo-500 flex items-center justify-center rounded-lg ease-linear transition-all duration-150">
                    <h4 className="group-hover:text-white text-xl text-center font-bold ease-linear transition-all duration-150">
                      {category.name}
                    </h4>
                  </div>
                </div>
              </Link>
            ))}
          </div>

          <div className="mt-20">
            <h4 className="text-2xl text-center font-light" data-aos="fade-up" data-aos-delay="100">
              We are the principles of
            </h4>
            <div
              className="mt-2 mx-12 lg:mx-32 grid gap-6 grid-cols-3 lg:grid-cols-5"
              data-aos="fade-up"
              data-aos-delay="200">
              {Object.values(PRODUCT_BRANDS).map((brand) => (
                <Link key={brand.slug} to={`/product/${brand.slug}`}>
                  <div className="group h-16 flex items-center justify-center">
                    <img
                      src={brand.image}
                      className="max-h-full max-w-full object-contain grayscale group-hover:grayscale-0 opacity-50 group-hover:opacity-100 ease-linear transition-all duration-150"
                      alt={brand.name}
                    />
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
