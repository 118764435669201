import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import {
  EnvelopeIcon,
  PhoneIcon,
  ClockIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { ONLINE_STORE_LINK } from '../constants/env';
import { PRODUCT_CATEGORIES } from '../constants/data';

const appLogo = require('../assets/images/solusi-bersama-informatika.png');

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      {/* TOP NAVIGATION BAR */}
      <header className="z-30 hidden lg:block bg-sky-950 text-white">
        <nav className="mx-auto flex max-w-6xl justify-between py-0 px-6">
          <div className="flex flex-1 gap-x-8">
            <a
              href="mailto:support@sbisolution.co.id"
              target="_blank"
              rel="noreferrer"
              className="flex items-center text-sm font-semibold">
              <EnvelopeIcon className="h-4 w-4 mr-1" aria-hidden="true" />
              support@sbisolution.co.id
            </a>
            <span className="flex items-center text-sm font-semibold">
              <PhoneIcon className="h-4 w-4 mr-1" aria-hidden="true" />
              (021) 588 0997
            </span>
            <span className="flex items-center text-sm font-semibold">
              <ClockIcon className="h-4 w-4 mr-1" aria-hidden="true" />
              Mon - Fri 9am - 5pm
            </span>
          </div>
          <div className="flex items-stretch">
            <a
              href={ONLINE_STORE_LINK}
              className="bg-gradient-to-br from-sky-600 to-indigo-500 hover:from-sky-700 hover:to-indigo-600 py-2 px-4 ease-linear transition-all duration-150"
              target="_blank"
              rel="noreferrer">
              <span className="text-sm font-semibold">Visit Online Store</span>
            </a>
          </div>
        </nav>
      </header>

      {/* NAVIGATION BAR */}
      <header className="z-40 sticky top-0 bg-white drop-shadow-md">
        <nav className="mx-auto flex max-w-6xl items-center justify-between py-2 px-6">
          <div className="flex lg:flex-1">
            <Link to="/">
              <span className="sr-only">PT Solusi Bersama Informatika</span>
              <img className="h-12 w-auto" src={appLogo} alt="PT Solusi Bersama Informatika" />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-slate-700"
              onClick={() => setMobileMenuOpen(true)}>
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <Popover.Group className="hidden lg:flex lg:gap-x-12">
            <HashLink
              smooth
              to="/#home"
              className="text-sm font-semibold hover:text-blue-500 ease-linear transition-all duration-150">
              Home
            </HashLink>

            <Popover className="relative">
              <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold hover:text-blue-500 ease-linear transition-all duration-150 outline-none">
                Product
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1">
                <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-2xl bg-white shadow-lg ring-1 ring-slate-200">
                  {({ close }) => (
                    <div className="p-4">
                      {Object.values(PRODUCT_CATEGORIES)
                        .filter((category) => category.type === 'product')
                        .map((category) => (
                          <Link
                            key={category.slug}
                            to={`/product/${category.slug}`}
                            className="group p-2 flex items-center cursor-pointer"
                            onClick={() => close()}>
                            <img
                              src={category.icon}
                              className="h-8 w-8 object-contain"
                              alt={`${category.name} | Solusi Bersama Informatika`}
                            />
                            <div className="flex-1 pl-6">
                              <h4 className="block font-semibold group-hover:text-blue-500 ease-linear transition-all duration-150">
                                {category.name}
                              </h4>
                              <p className="text-sm text-slate-600">{category.description}</p>
                            </div>
                          </Link>
                        ))}
                    </div>
                  )}
                </Popover.Panel>
              </Transition>
            </Popover>

            <HashLink
              smooth
              to="/#project"
              className="text-sm font-semibold hover:text-blue-500 ease-linear transition-all duration-150">
              Projects
            </HashLink>
            <HashLink
              smooth
              to="/#testimonial"
              className="text-sm font-semibold hover:text-blue-500 ease-linear transition-all duration-150">
              Testimonial
            </HashLink>
            <HashLink
              smooth
              to="/#contact"
              className="text-sm font-semibold hover:text-blue-500 ease-linear transition-all duration-150">
              Contact
            </HashLink>
          </Popover.Group>
        </nav>

        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-slate-900/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">PT Solusi Bersama Informatika</span>
                <img className="h-10 w-auto" src={appLogo} alt="PT Solusi Bersama Informatika" />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-slate-700"
                onClick={() => setMobileMenuOpen(false)}>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6">
              <HashLink
                smooth
                to="/#home"
                className="px-3 py-2 block font-semibold hover:text-blue-500 ease-linear transition-all duration-150"
                onClick={() => setMobileMenuOpen(false)}>
                Home
              </HashLink>

              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="px-3 py-2 flex w-full items-center justify-between font-semibold hover:text-blue-500 ease-linear transition-all duration-150">
                      Product
                      <ChevronDownIcon
                        className={`${
                          open ? 'rotate-180' : ''
                        } h-5 w-5 flex-none ease-linear transition-all duration-150`}
                        aria-hidden="true"
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="mt-2 space-y-2">
                      {Object.values(PRODUCT_CATEGORIES)
                        .filter((category) => category.type === 'product')
                        .map((category) => (
                          <Link
                            key={category.slug}
                            to={`/product/${category.slug}`}
                            className="block pl-10 pr-3 py-2 text-sm font-semibold hover:text-blue-500 ease-linear transition-all duration-150"
                            onClick={() => setMobileMenuOpen(false)}>
                            {category.name}
                          </Link>
                        ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <HashLink
                smooth
                to="/#project"
                className="mt-2 px-3 py-2 block font-semibold hover:text-blue-500 ease-linear transition-all duration-150"
                onClick={() => setMobileMenuOpen(false)}>
                Projects
              </HashLink>
              <HashLink
                smooth
                to="/#testimonial"
                className="mt-2 px-3 py-2 block font-semibold hover:text-blue-500 ease-linear transition-all duration-150"
                onClick={() => setMobileMenuOpen(false)}>
                Testimonial
              </HashLink>
              <HashLink
                smooth
                to="/#contact"
                className="mt-2 px-3 py-2 block font-semibold hover:text-blue-500 ease-linear transition-all duration-150"
                onClick={() => setMobileMenuOpen(false)}>
                Contact
              </HashLink>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </>
  );
}
