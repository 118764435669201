import React from 'react';
import { UserGroupIcon, RocketLaunchIcon, BriefcaseIcon } from '@heroicons/react/24/outline';

const aboutImage = require('../../assets/images/about.jpg');

export default function AboutSection() {
  return (
    <>
      <section id="about" className="bg-slate-100">
        <div className="mx-auto max-w-6xl px-6 py-20">
          <div className="lg:flex items-center">
            <div className="lg:w-5/12 flex self-stretch">
              <img
                src={aboutImage}
                className="w-full h-full object-cover"
                alt=""
                data-aos="fade-right"
              />
            </div>
            <div className="px-10 lg:pl-12 lg:pr-0 py-10 flex-1">
              <div data-aos="fade-up" data-aos-delay="100">
                <h4 className="text-xl">About Us</h4>
                <h4 className="mt-4 text-4xl font-bold leading-10">
                  Technology Company that focus on the field of intelligent business
                </h4>
                <p className="mt-4 text-lg">
                  With the rapid development of Internet, SBI rises in response to customer needs on
                  pursuing a better life. As a representative of innovative technology companies,
                  SBI continues to develop and launch various platform tools and products with
                  industrial design originality
                </p>
              </div>

              <div>
                <div className="group mt-12 flex" data-aos="fade-up" data-aos-delay="200">
                  <div className="h-16 w-16 flex bg-white group-hover:bg-gradient-to-br from-sky-500 to-indigo-500 rounded-full items-center justify-center border border-sky-500 ease-linear transition-all duration-150">
                    <UserGroupIcon className="h-8 w-8 text-sky-500 group-hover:text-white ease-linear transition-all duration-150" />
                  </div>
                  <div className="pl-6 flex-1">
                    <h4 className="text-xl font-bold">Individual Approach</h4>
                    <p className="mt-4">
                      We thoroughly study our client's case. Depending on the project requirements,
                      we engage a dedicated team to analyze client specific objectives and needs,
                      and provide the solutions that suits them
                    </p>
                  </div>
                </div>

                <div className="group mt-12 flex" data-aos="fade-up" data-aos-delay="300">
                  <div className="h-16 w-16 flex bg-white group-hover:bg-gradient-to-br from-sky-500 to-indigo-500 rounded-full items-center justify-center border border-sky-500 ease-linear transition-all duration-150">
                    <RocketLaunchIcon className="h-8 w-8 text-sky-500 group-hover:text-white ease-linear transition-all duration-150" />
                  </div>
                  <div className="pl-6 flex-1">
                    <h4 className="text-xl font-bold">Solve Business Problems</h4>
                    <p className="mt-4">
                      We sit on the same side of the table as you to provide the business solution
                      for your business problems. We don't try to sell you the latest techno gizmo -
                      instead - we listen to your business problems and offer several strategies to
                      resolve the root of the problems
                    </p>
                  </div>
                </div>

                <div className="group mt-12 flex" data-aos="fade-up" data-aos-delay="400">
                  <div className="h-16 w-16 flex bg-white group-hover:bg-gradient-to-br from-sky-500 to-indigo-500 rounded-full items-center justify-center border border-sky-500 ease-linear transition-all duration-150">
                    <BriefcaseIcon className="h-8 w-8 text-sky-500 group-hover:text-white ease-linear transition-all duration-150" />
                  </div>
                  <div className="pl-6 flex-1">
                    <h4 className="text-xl font-bold">Trusted by Big Startup & Company</h4>
                    <p className="mt-4">
                      We've been trusted as a business partner of some big startup and company, so
                      let us hear your story and make your company one of them
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
