import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams, Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import { APP_URL, APP_NAME } from '../../constants/env';
import { PRODUCTS, ECOMMERCE } from '../../constants/data';

export default function ProductDetail() {
  const location = useLocation();

  const { slug } = useParams();
  const product = PRODUCTS.find((p) => p.slug === slug);

  const handleDragStart = (e) => e.preventDefault();

  const imageItems = [1, 2, 3, 4, 5]
    .filter((image) => product[`image${image}`])
    .map((image) => (
      <div key={image} className="p-10 w-full bg-slate-50 rounded-lg">
        <img
          // eslint-disable-next-line global-require
          src={require(`../../assets/images/products/${product.slug}/${product[`image${image}`]}`)}
          className="w-full h-96 object-contain ease-linear transition-all duration-150"
          onDragStart={handleDragStart}
          alt={product.name}
        />
      </div>
    ));

  const relatedProducts =
    product.relatedProducts && product.relatedProducts.length > 0
      ? PRODUCTS.filter((p) => product.relatedProducts.indexOf(p.slug) >= 0)
      : [];

  return (
    <>
      <Helmet>
        <link rel="canonical" href={APP_URL} />
        <title>{product.metaTitle}</title>
        <meta name="description" content={product.metaDescription} />
        <meta name="keywords" content={product.metaKeywords} />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={APP_NAME} />
        <meta property="og:locale" content="id_ID" />
        <meta property="og:title" content={product.metaTitle} />
        <meta property="og:description" content={product.metaDescription} />
        <meta property="og:image" content={product.image1} />
        <meta property="og:image:alt" content={product.name} />
        <meta property="og:url" content={`${APP_URL}${location.pathname}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={product.metaTitle} />
        <meta name="twitter:description" content={product.metaDescription} />
        <meta name="twitter:image" content={product.image1} />
        <meta name="twitter:image:alt" content={product.name} />
      </Helmet>

      <section className="bg-white">
        <div className="mx-auto max-w-6xl px-8 py-10">
          <div className="lg:hidden">
            <h1 className="text-2xl font-bold">{product.name}</h1>
            <h2 className="text-slate-400 text-md">{product.category}</h2>
          </div>

          <div className="mt-4 grid gap-6 grid-cols-1 lg:grid-cols-2">
            <div className="group relative">
              <AliceCarousel
                disableSlideInfo={false}
                infinite
                mouseTracking
                items={imageItems}
                renderPrevButton={({ isDisabled }) => (
                  <div
                    className="absolute opacity-0 group-hover:opacity-100 left-2 group-hover:left-5 top-52
                      h-10 w-10 flex items-center justify-center rounded-full bg-slate-400 cursor-pointer
                      ease-linear transition-all duration-150">
                    <ChevronLeftIcon className="-ml-1 h-8 w-8 text-white" />
                  </div>
                )}
                renderNextButton={({ isDisabled }) => (
                  <div
                    className="absolute opacity-0 group-hover:opacity-100 right-2 group-hover:right-5 top-52
                      h-10 w-10 flex items-center justify-center rounded-full bg-slate-400 cursor-pointer
                      ease-linear transition-all duration-150">
                    <ChevronRightIcon className="ml-1 h-8 w-8 text-white" />
                  </div>
                )}
              />
            </div>
            <div className="pt-2">
              <div className="hidden lg:flex items-center">
                <div className="flex-1">
                  <h1 className="text-2xl font-bold">{product.name}</h1>
                  <h2 className="-mt-1 text-slate-400 text-md">{product.category.toUpperCase()}</h2>
                </div>
                <div>
                  <a
                    // eslint-disable-next-line global-require
                    href={require(`../../assets/images/products/${product.slug}/${product.productCatalog}`)}
                    target="_blank"
                    rel="noreferrer"
                    className="px-4 py-2 bg-gradient-to-br from-sky-500 to-indigo-500 rounded-full text-white text-center font-semibold ease-linear transition-all duration-150">
                    Product Catalog
                  </a>
                </div>
              </div>
              <div className="mt-0 lg:mt-6 text-justify">
                <p>{product.description}</p>
              </div>

              <div className="mt-10 mb-10 grid gap-2 grid-cols-2">
                <a
                  href={`https://wa.me/6282261500700?text=Halo SBI, saya tertarik dengan ${product.name}, mohon info lebih lanjut mengenai produk tersebut`}
                  target="_blank"
                  rel="noreferrer">
                  <div
                    className="px-4 py-4 rounded-lg"
                    style={{ backgroundColor: ECOMMERCE.WHATSAPP.color }}>
                    <img
                      src={ECOMMERCE.WHATSAPP.image}
                      className="w-full h-8 object-contain"
                      alt="WhatsApp"
                    />
                  </div>
                </a>

                {product.tokopedia && (
                  <a href="#" target="_blank" rel="noreferrer">
                    <div
                      className="px-4 py-4 rounded-lg"
                      style={{ backgroundColor: ECOMMERCE.TOKOPEDIA.color }}>
                      <img
                        src={ECOMMERCE.TOKOPEDIA.image}
                        className="w-full h-8 object-contain"
                        alt="Tokopedia"
                      />
                    </div>
                  </a>
                )}
                {product.shopee && (
                  <a href="#" target="_blank" rel="noreferrer">
                    <div
                      className="px-4 py-4 rounded-lg"
                      style={{ backgroundColor: ECOMMERCE.SHOPEE.color }}>
                      <img
                        src={ECOMMERCE.SHOPEE.image}
                        className="w-full h-8 object-contain"
                        alt="Tokopedia"
                      />
                    </div>
                  </a>
                )}
                {product.tiktok && (
                  <a href="#" target="_blank" rel="noreferrer">
                    <div
                      className="px-4 py-4 rounded-lg"
                      style={{ backgroundColor: ECOMMERCE.TIKTOK.color }}>
                      <img
                        src={ECOMMERCE.TIKTOK.image}
                        className="w-full h-8 object-contain"
                        alt="Tokopedia"
                      />
                    </div>
                  </a>
                )}
              </div>
            </div>
          </div>

          <div className="mt-6">
            <h4 className="text-2xl font-bold leading-10">Spesifikasi Produk</h4>
            <div className="mt-4 grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {Object.entries(product.specifications).map((specification) => (
                <div key={specification[0]} className="bg-slate-50 p-6 rounded-md">
                  <h4 className="-mt-1 text-slate-700 text-xl font-bold ease-linear transition-all duration-150">
                    {specification[0]}
                  </h4>
                  <p className="mt-1 text-slate-400 text-md font-semibold whitespace-pre-line">
                    {specification[1]}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {relatedProducts.length > 0 && (
            <div className="mt-20 mb-8">
              <h4 className="text-2xl font-bold leading-10">Produk Terkait Lainnya</h4>
              <div className="mt-4 grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                {relatedProducts.map((relatedProduct) => (
                  <Link key={relatedProduct.slug} to={`/product/${relatedProduct.slug}`}>
                    <div className="group bg-slate-100 p-6 rounded-md cursor-pointer">
                      <img
                        className="w-full h-60 object-contain scale-90 group-hover:scale-100 ease-linear transition-all duration-150"
                        // eslint-disable-next-line global-require
                        src={require(`../../assets/images/products/${relatedProduct.slug}/${relatedProduct.image1}`)}
                        alt={relatedProduct.name}
                      />
                      <h4 className="mt-4 text-center group-hover:text-blue-500 text-xl font-bold ease-linear transition-all duration-150">
                        {relatedProduct.name}
                      </h4>
                      <h5 className="text-slate-400 text-center text-md font-bold">
                        {relatedProduct.category}
                      </h5>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
