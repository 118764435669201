/* eslint-disable global-require */

export const ECOMMERCE = {
  WHATSAPP: { color: '#24D366', image: require('../assets/images/ecommerce/whatsapp.png') },
  TOKOPEDIA: { color: '#3FB747', image: require('../assets/images/ecommerce/tokopedia.png') },
  SHOPEE: { color: '#EF5235', image: require('../assets/images/ecommerce/shopee.png') },
  TIKTOK: { color: '#080808', image: require('../assets/images/ecommerce/tiktok.png') },
};

export const CLIENTS = [
  {
    name: 'Grab',
    image: require('../assets/images/clients/grab.png'),
  },
  {
    name: 'Shopee',
    image: require('../assets/images/clients/shopee.png'),
  },
  {
    name: 'Chareon Pokphand Indonesia',
    image: require('../assets/images/clients/charoen-pokphand.png'),
  },
  {
    name: 'J&T Express',
    image: require('../assets/images/clients/jet.png'),
  },
  {
    name: 'HokBen (Hoka-Hoka Bento)',
    image: require('../assets/images/clients/hoka-hoka-bento.png'),
  },
  {
    name: 'Indogrosir',
    image: require('../assets/images/clients/indogrosir.png'),
  },
  {
    name: 'Lazada E-Logistics',
    image: require('../assets/images/clients/lazada-elogistics.png'),
  },
  {
    name: 'Ninja Express',
    image: require('../assets/images/clients/ninja-xpress.png'),
  },
  {
    name: 'Prima Freshmart',
    image: require('../assets/images/clients/prima-freshmart.png'),
  },
  {
    name: 'KKV',
    image: require('../assets/images/clients/kkv.png'),
  },
];

export const PRODUCT_CATEGORIES = {
  MOBILE_TERMINAL: {
    name: 'Mobile Terminal',
    slug: 'mobile-terminal',
    description: 'Applicable to the retail, restaurant, logistics and manufacturing businesses',
    background: require('../assets/images/categories/mobile-terminal.jpg'),
    icon: require('../assets/images/categories/icon/mobile-terminal.png'),
    type: 'product',
    showInFooter: true,
  },
  PAYMENT_TERMINAL: {
    name: 'Payment Terminal',
    slug: 'payment-terminal',
    description: 'Device for omnichannel payments – bank cards, QR codes and e-wallets',
    background: require('../assets/images/categories/payment-terminal.jpg'),
    icon: require('../assets/images/categories/icon/payment-terminal.png'),
    type: 'product',
    showInFooter: true,
  },
  DESKTOP_TERMINAL: {
    name: 'Desktop Terminal',
    slug: 'desktop-terminal',
    description: 'Desktop series for retail, restaurant, logistics and manufacturing businesses',
    background: require('../assets/images/categories/desktop-terminal.jpg'),
    icon: require('../assets/images/categories/icon/desktop-terminal.png'),
    type: 'product',
    showInFooter: true,
  },
  KIOSK: {
    name: 'Self Service / Kiosk',
    slug: 'kiosk',
    description:
      'Kiosk series that applicable to restaurants, retail stores, hotels and other sectors',
    background: require('../assets/images/categories/kiosk.jpg'),
    icon: require('../assets/images/categories/icon/kiosk.png'),
    type: 'product',
  },
  PDT: {
    name: 'PDT / Handheld Terminal',
    slug: 'pdt',
    description: 'Handheld devices that can read barcodes, RFID tags and contactless cards',
    background: require('../assets/images/categories/pdt.jpg'),
    icon: require('../assets/images/categories/icon/pdt.png'),
    type: 'product',
    showInFooter: true,
  },
  RFID_TERMINAL: {
    name: 'RFID Terminal',
    slug: 'rfid-terminal',
    description: 'Handheld devices that focused on long-distant reading RFID tags',
    background: require('../assets/images/categories/rfid-terminal.jpg'),
    icon: require('../assets/images/categories/icon/rfid-terminal.png'),
    type: 'product',
  },
  BARCODE_SCANNER: {
    name: 'Barcode Scanner',
    slug: 'barcode-scanner',
    description: 'Barcode scanner devices specialize in long-distant scanning with no constrain',
    background: require('../assets/images/categories/barcode-scanner.jpg'),
    icon: require('../assets/images/categories/icon/barcode-scanner.png'),
    type: 'product',
  },
  BUSINESS_CONSULTATION: {
    name: 'Business Consultation',
    slug: 'business-consultation',
    background: require('../assets/images/categories/business-consultation.jpg'),
    type: 'service',
  },
  SOFTWARE_DEVELOPMENT: {
    name: 'Software Development',
    slug: 'software-development',
    background: require('../assets/images/categories/software-development.jpg'),
    type: 'service',
  },
};

export const PRODUCT_BRANDS = {
  SMARTECH: {
    name: 'SMARTECH',
    slug: 'smartech',
    image: require('../assets/images/brands/smartech.png'),
    description: 'SMARTECH provides merchants around the world with great digital solutions',
  },
  IDATA: {
    name: 'iData',
    slug: 'idata',
    image: require('../assets/images/brands/idata.png'),
    description: 'Intelligence to Future, connect the digital world through iDATA',
  },
  SUNMI: {
    name: 'SUNMI',
    slug: 'sunmi',
    image: require('../assets/images/brands/sunmi.png'),
    description:
      'SUNMI, being applicable to the retail, restaurant, logistics and manufacturing businesses, have helped business owners to accelerate their digital transformation in store operations',
  },
  IMIN: {
    name: 'iMin',
    slug: 'imin',
    image: require('../assets/images/brands/imin.png'),
    description: 'Scale your business with Android POS from iMin',
  },
  KAICOM: {
    name: 'KAICOM',
    slug: 'kaicom',
    image: require('../assets/images/brands/kaicom.png'),
    description:
      'Committed to the IOT solution provider of terminal equipment research and development, system, and software design and development',
  },
};

export const PRODUCTS = [
  {
    name: 'SUNMI V2',
    slug: 'sunmi-v2',
    metaTitle: 'SUNMI V2 Mesin Kasir Terbaik untuk Bisnis Anda | Solusi Bersama Informatika',
    metaDescription:
      'Temukan SUNMI V2, mesin kasir terbaik untuk bisnis Anda. Hemat waktu, tingkatkan efisiensi, dan kelola transaksi dengan mudah. Beli online di Solusi Bersama Informatika',
    metaKeywords:
      'SUNMI, SUNMI V2, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.MOBILE_TERMINAL.name,
    image1: 'sunmi-v2-01.png',
    image2: 'sunmi-v2-02.png',
    image3: 'sunmi-v2-03.png',
    image4: 'sunmi-v2-04.png',
    image5: 'sunmi-v2-05.png',
    productCatalog: 'sbisolution-sunmi-v2.pdf',
    description:
      'SUNMI V2 adalah mesin kasir terbaik yang dirancang khusus untuk memenuhi kebutuhan bisnis Anda. Dengan fitur-fitur unggulan dan kinerja yang handal, SUNMI V2 membantu Anda menghemat waktu, meningkatkan efisiensi, dan dengan mudah mengelola transaksi harian. Dengan antarmuka yang intuitif, printer termal terintegrasi, pemindai barcode berkualitas tinggi, dan konektivitas yang luas, SUNMI V2 menjadi pilihan ideal untuk berbagai jenis bisnis seperti toko, restoran, kafe, dan bisnis ritel lainnya. Dapatkan SUNMI V2 secara online di Solusi Bersama Informatika untuk mengoptimalkan pengelolaan kasir Anda dengan solusi terbaik dalam industri',
    specifications: {
      OS: 'Android OS, SUNMI OS',
      CPU: 'Cortex-A53 Quad Core 1.3GHz or Quad Core 1.5GHz',
      Memory: '1GB RAM + 8GB ROM\n2GB RAM + 16GB ROM (optional)',
      Display: '5.45" HD+ 1440×720px 18:9 IPS',
      Adapter: 'Input: AC 100~240V\nOutput: 5V/2A',
      'Wi-Fi': '2.4G/5G, Support lEEE 802.11 a/b/g/n',
      Bluetooth: 'Support Bluetooth 4.0',
      Speaker: '95dB',
      'Total Weight': '364g',
      Battery: 'Lithium Polymer Battery 2580mAh 7.6V',
      'Communication Methods': '4G/3G/2G',
      Interface: 'Support Type-C, Support OTG',
      Dimensions: '219.1 x 80 x 17.8mm',
      'SIM Card Slot': 'NANO SIM card × 1',
      GPS: 'GPS, AGPS',
      Environment: 'Operating Temperature : -10~50℃\nStorage Temperature : -20~60℃',
      Printer: 'Seiko thermal print head\nPrint speed 70mm/s\nSupport roll of 58mm',
      Camera: '5.0MP Auto Focus with Flash LED',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['sunmi-v2s', 'sunmi-v2-pro', 'imin-m2', 'smartech-r330'],
  },
  {
    name: 'SUNMI V2s',
    slug: 'sunmi-v2s',
    metaTitle: 'SUNMI V2s Mesin Kasir Terbaik untuk Bisnis Anda | Solusi Bersama Informatika',
    metaDescription:
      'Temukan SUNMI V2s, mesin kasir terbaik untuk bisnis Anda. Hemat waktu, tingkatkan efisiensi, dan kelola transaksi dengan mudah. Beli online di Solusi Bersama Informatika',
    metaKeywords:
      'SUNMI, SUNMI V2s, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.MOBILE_TERMINAL.name,
    image1: 'sunmi-v2s-01.png',
    image2: 'sunmi-v2s-02.png',
    image3: 'sunmi-v2s-03.png',
    image4: 'sunmi-v2s-04.png',
    image5: 'sunmi-v2s-05.png',
    productCatalog: 'sbisolution-sunmi-v2s.pdf',
    description:
      'SUNMI V2s adalah mesin kasir terbaik yang dirancang khusus untuk memenuhi kebutuhan bisnis Anda. Dengan fitur-fitur unggulan dan kinerja yang handal, SUNMI V2s membantu Anda menghemat waktu, meningkatkan efisiensi, dan dengan mudah mengelola transaksi harian. Dengan antarmuka yang intuitif, printer termal terintegrasi, pemindai barcode berkualitas tinggi, dan konektivitas yang luas, SUNMI V2s menjadi pilihan ideal untuk berbagai jenis bisnis seperti toko, restoran, kafe, dan bisnis ritel lainnya. Dapatkan SUNMI V2s secara online di Solusi Bersama Informatika untuk mengoptimalkan pengelolaan kasir Anda dengan solusi terbaik dalam industri',
    specifications: {
      OS: 'SUNMI OS (based on Android 11 / Android 12 Go Edition)',
      CPU: 'Quad-Core 2.0GHz',
      Memory: '3GB RAM + 32GB ROM (Android 11) / 2GB RAM + 16GB ROM (Android 12 Go)',
      Display: '5.5"HD+ 1440×720, 18:9 IPS',
      Camera: '5.0MP Auto Focus',
      NFC: 'Optional',
      Scanner: 'Optional',
      Speaker: '1.1W, 95dB',
      'PSAM slot': 'Optional',
      'SIM & MicroSD Slot': 'Nano SIM *2 \nMicroSD *1',
      Printer:
        'High speed, 58mm thermal printer\nPrint speed: 80mm/s\nPaper roll: Φ40mm\nPrint speed 80mm/s MAX\nSupport till roll of 58mm width, 40mm roll diameter\nSupport receipt printing',
      Communication: '4G/3G/2G',
      'Wi-Fi': '2.4GHz/5GHz; IEEE 802.11 a/b/g/n/ac',
      Bluetooth: 'Support Bluetooth 2.1/3.0/4.2; Support BLE',
      GPS: 'GPS, AGPS',
      Interface: 'Support Type-C, Support OTG, Support 3.5mm earphone jack',
      Battery: 'Removable, Lithium Polymer Battery 7.7V/3500mAh（equal to 3.85V/7000mAh)',
      Adapter: 'INPUT: AC 100~240V; OUTPUT: 5V/2A',
      'Dimensions (L*W*H)': '219.1*80*17.8mm',
      Weight: '417g (including battery); 300g(without battery)',
      'Drop Test': '1.2m (Data from Sunmi Lab)',
      Environment: 'Operating temp: -10~50℃; Storage temp: -20~60℃',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['sunmi-v2', 'sunmi-v2-pro', 'imin-m2', 'smartech-r330'],
  },
  {
    name: 'SUNMI V2 PRO',
    slug: 'sunmi-v2-pro',
    metaTitle:
      'SUNMI V2 PRO Mesin Kasir Profesional untuk Bisnis Anda | Solusi Bersama Informatika',
    metaDescription:
      'Temukan SUNMI V2 PRO, mesin kasir profesional untuk bisnis Anda. Hemat waktu, tingkatkan efisiensi, dan kelola transaksi dengan mudah. Beli online di Solusi Bersama Informatika',
    metaKeywords:
      'SUNMI, SUNMI V2 PRO, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.MOBILE_TERMINAL.name,
    image1: 'sunmi-v2-pro-01.png',
    image2: 'sunmi-v2-pro-02.png',
    image3: 'sunmi-v2-pro-03.png',
    image4: 'sunmi-v2-pro-04.png',
    image5: 'sunmi-v2-pro-05.png',
    productCatalog: 'sbisolution-sunmi-v2-pro.pdf',
    description:
      'SUNMI V2 PRO merupakan mesin kasir profesional yang dirancang khusus untuk memenuhi kebutuhan bisnis Anda. Dengan fitur-fitur unggulan dan kinerja yang handal, SUNMI V2 PRO membantu mengoptimalkan waktu, meningkatkan efisiensi, dan memudahkan pengelolaan transaksi harian. Dengan antarmuka yang intuitif, printer termal terintegrasi, pemindai barcode berkualitas tinggi, dan konektivitas yang luas, SUNMI V2 PRO menjadi pilihan terbaik untuk berbagai jenis bisnis, seperti toko, restoran, kafe, dan ritel lainnya. Dapatkan SUNMI V2 PRO secara online di Solusi Bersama Informatika untuk meningkatkan efisiensi kasir dan mempercepat pertumbuhan bisnis Anda',
    specifications: {
      OS: 'Android OS, SUNMI OS',
      CPU: 'Cortex-A53 Quad Core 1.3GHz or Quad Core 1.5GHz',
      Memory: '1GB RAM + 8GB ROM / 2GB RAM + 16GB ROM (optional)',
      Display: '5.45" HD+ 1440×720 18:9 IPS',
      Adapter: 'INPUT: AC 100~240V; OUTPUT: 5V/2A',
      'Wi-Fi': '2.4G/5G, Support lEEE 802.11 a/b/g/n',
      Bluetooth: 'Support Bluetooth 4.0',
      Speaker: '95dB',
      'Total Weight': '364g',
      Battery: 'Lithium Polymer Battery 2580mAh 7.6V',
      'Communication Methods': '4G/3G/2G',
      Interface: 'Support Type-C, Support OTG',
      Dimensions: '219.1*80*17.8mm',
      'SIM Card Slot': 'NANO SIM card × 1',
      GPS: 'GPS, AGPS',
      Environment: 'Operating temperature: -10~50℃\nstorage temperature: -20~60℃',
      Printer:
        'Seiko thermal print head\nPrint speed 70mm/s MAX\nSupport till roll of 58mm width, 50mm OD',
      Camera: '5.0MP Auto Focus with Flash LED',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['sunmi-v2', 'sunmi-v2s', 'imin-m2', 'smartech-r330'],
  },
  {
    name: 'SUNMI L2',
    slug: 'sunmi-l2',
    metaTitle:
      'SUNMI L2 Barcode Scanner Solusi Terbaik untuk Meningkatkan Efisiensi Bisnis | Solusi Bersama Informatika',
    metaDescription:
      'Temukan Barcode Scanner SUNMI L2, solusi terbaik untuk meningkatkan efisiensi bisnis Anda. Dapatkan kecepatan dan akurasi tinggi dalam pemindaian barcode. Beli online di Solusi Bersama Informatika',
    metaKeywords:
      'SUNMI, SUNMI L2, barcode scanner, pdt, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.PDT.name,
    image1: 'sunmi-l2-01.png',
    image2: 'sunmi-l2-02.png',
    image3: 'sunmi-l2-03.png',
    image4: 'sunmi-l2-04.png',
    image5: 'sunmi-l2-05.png',
    productCatalog: 'sbisolution-sunmi-l2.pdf',
    description:
      'Barcode Scanner SUNMI L2 adalah solusi terbaik untuk meningkatkan efisiensi bisnis Anda melalui pemindaian barcode. Dengan kecepatan dan akurasi tinggi, SUNMI L2 memungkinkan Anda memindai barcode dengan cepat dan akurat, mengoptimalkan pengelolaan inventaris, penjualan, dan pemantauan stok. Dilengkapi dengan teknologi canggih, antarmuka yang user-friendly, dan daya tahan baterai yang luar biasa, SUNMI L2 menjadi pilihan ideal untuk berbagai jenis bisnis, seperti toko, gudang, atau pameran. Dapatkan Barcode Scanner SUNMI L2 secara online di Solusi Bersama Informatika dan tingkatkan efisiensi operasional serta kecepatan dalam pemindaian barcode untuk kesuksesan bisnis Anda',
    specifications: {
      OS: 'Android OS SUNMI OS',
      CPU: 'Qualcomm quad-core 1.4GHz',
      Memory: '2GB RAM + 16GB ROM',
      Display: '5" HD 1280×720, IPS Capacitive Multi-touch Screen',
      'Power Adapter': 'Input: AC 100-240V; Output: DC 5V/2A',
      'Wi-Fi': '2.4G/5G; Support IEEE 802.11 a/b/g/n',
      Bluetooth: 'Support BLE\nSupport Bluetooth 2.1/3.0/4.2',
      'Speaker/MIC': '1W',
      NFC: 'Mifare/Felica/ISO 14443\nType A/B IS15693',
      Battery: 'Non-removable lithium battery 3.8V/5000mAh',
      Communication: '2G/3G/4G',
      Camera: '5.0MP, Flashlight, AF\nSupport 1D/2D\nSupport 720p video recording',
      'External Memory Card': 'Support, Up to 64GB',
      'SIM Card Slot': '1 × SIM Card slot',
      Environment:
        'Operating temperature: -20℃ ~ 55℃\nStorage temperature: -20℃ ~ 60℃\nProtection rating: IP67\nFalling height: 1.2 m',
      Scanner: 'Support 1D/2D code scanning with international brand Zebra scanning head',
    },
    additionalContent: null,
    showInFooter: false,
    relatedProducts: ['imin-swift-1', 'idata-k1s', 'idata-70', 'kaicom-w660'],
  },
  {
    name: 'SUNMI P2',
    slug: 'sunmi-p2',
    metaTitle: 'SUNMI P2 Solusi Pembayaran Terbaik untuk Bisnis Anda | Solusi Bersama Informatika',
    metaDescription:
      'Temukan SUNMI P2, solusi pembayaran terbaik untuk bisnis Anda. Mudah, cepat, dan aman. Beli online di Solusi Bersama Informatika',
    metaKeywords:
      'SUNMI, SUNMI P2, payment terminal, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.PAYMENT_TERMINAL.name,
    image1: 'sunmi-p2-01.png',
    image2: 'sunmi-p2-02.png',
    image3: 'sunmi-p2-03.png',
    image4: 'sunmi-p2-04.png',
    image5: 'sunmi-p2-05.png',
    productCatalog: 'sbisolution-sunmi-p2.pdf',
    description:
      'SUNMI P2 adalah solusi pembayaran terbaik untuk bisnis Anda. Dengan desain yang elegan dan fitur-fitur canggih, SUNMI P2 memungkinkan Anda menerima pembayaran dengan mudah, cepat, dan aman. Dilengkapi dengan layar sentuh yang responsif, printer termal terintegrasi, dan konektivitas yang luas, SUNMI P2 memberikan pengalaman pembayaran yang nyaman dan efisien. Dapatkan SUNMI P2 secara online di Solusi Bersama Informatika dan optimalkan proses pembayaran dalam bisnis Anda dengan solusi pembayaran terpercaya',
    specifications: {
      OS: 'Android OS Sunmi OS',
      CPU: 'Quad-core 1.5GHz or 1.3GHz',
      Memory: '1GB RAM+8GB ROM',
      Display: '5.5" HD+ 1440×720, IPS Touch Screen Capacitive multi-touch',
      Printer:
        'High-speed thermal printer; Print speed: 70mm/s; Till roll width/diameter: 58mm/40mm',
      'Communication Methods': 'LTE/UMTS/GSM',
      'Wi-Fi': '2.4G/5G, Supports IEEE 802.11 a/b/g/n',
      Bluetooth: 'Supports 2.1/3.0/4.0 BT',
      GPS: 'GPS, AGPS',
      Camera: 'Front camera: 0.3 Megapixel(Optional); Rear camera: 5 Megapixel Auto Focus Camera',
      Fingerprint: 'FAP10, FBI/STQC (Optional)',
      Battery: 'Li-polymer battery 7.6V/2540mAh',
      Adaptor: 'Input: AC100-240V, 0.3A; Output: 5V/2A',
      Dimensions: '222*81.6*17.6mm',
      'Total Weight': '400g',
      Environment: 'Operating Temperature: -10°C～50°C; Storage Temperature: -20°C～60°C',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['sunmi-p2-pro', 'sunmi-p2-se'],
  },
  {
    name: 'SUNMI P2 PRO',
    slug: 'sunmi-p2-pro',
    metaTitle:
      'SUNMI P2 PRO Solusi Pembayaran Profesional untuk Bisnis Anda | Solusi Bersama Informatika',
    metaDescription:
      'Temukan SUNMI P2 PRO, solusi pembayaran profesional untuk bisnis Anda. Mudah, cepat, dan aman. Beli online di Solusi Bersama Informatika',
    metaKeywords:
      'SUNMI, SUNMI P2 PRO, payment terminal, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.PAYMENT_TERMINAL.name,
    image1: 'sunmi-p2-pro-01.png',
    image2: 'sunmi-p2-pro-02.png',
    image3: 'sunmi-p2-pro-03.png',
    image4: 'sunmi-p2-pro-04.png',
    image5: 'sunmi-p2-pro-05.png',
    productCatalog: 'sbisolution-sunmi-p2-pro.pdf',
    description:
      'SUNMI P2 PRO adalah solusi pembayaran profesional yang dirancang untuk memenuhi kebutuhan bisnis Anda. Dengan fitur-fitur canggih dan performa yang handal, SUNMI P2 PRO memungkinkan Anda menerima pembayaran dengan mudah, cepat, dan aman. Dilengkapi dengan layar sentuh yang responsif, printer termal terintegrasi, dan konektivitas yang luas, SUNMI P2 PRO memberikan pengalaman pembayaran yang nyaman dan efisien. Dapatkan SUNMI P2 PRO secara online di Solusi Bersama Informatika dan optimalkan proses pembayaran dalam bisnis Anda dengan solusi pembayaran profesional yang terpercaya',
    specifications: {
      OS: 'Android OS Sunmi OS',
      CPU: 'Quad-core 1.4Hz CPU',
      Memory: '1GB RAM+8GB ROM; 2GB RAM+16GB(optional)',
      Display: '5.99" HD+ 1440x720 IPS',
      Camera: '5.0MP, Flash, AF; Support 1D/2D barcode scan',
      'Wi-Fi': '2.4G/5GHz, IEEE 802.11 a/b/g/n',
      Bluetooth: 'Bluetooth 2.1/3.0/4.2; Support BLE',
      GPS: 'AGPS GPS',
      '1D Barcode Recognition': 'Optional 1D professional hardware decoding scanner',
      Battery: 'Built-in lithium polymer battery 7.6V/2580mAh',
      Interface: '1 Type C USB 2.0 HS OTG support',
      Sensor: 'Gravity Sensor (G-sensor)',
      Speaker: '1W',
      Printer: '58mm high speed (70mm/s) thermal printer; Till roll OD 40mm max',
      Adaptor: 'Input: AC 100~240V; Output: 5V/2A',
      Environment: 'Operating temperature：-10℃~50℃; Storage temperature：-20℃~60℃',
      'Total Weight': 'Item weight: 430g (battery incl.)',
      'Communication Methods': '2G: (B3/B8); 3G: WCDMA (B1/B8); 4G: B1/B3/B7/B20/B38/B40',
      Dimensions: '224.9*84.2*17.5(50.4)mm',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['sunmi-p2', 'sunmi-p2-se'],
  },
  {
    name: 'SUNMI P2 SE',
    slug: 'sunmi-p2-se',
    metaTitle:
      'SUNMI P2 PRO Solusi Pembayaran Profesional untuk Bisnis Anda | Solusi Bersama Informatika',
    metaDescription:
      'SUNMI P2 SE, solusi pembayaran terpercaya untuk bisnis Anda. Menerima pembayaran dengan mudah dan aman. Beli online di Solusi Bersama Informatika',
    metaKeywords:
      'SUNMI, SUNMI P2 SE, payment terminal, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.PAYMENT_TERMINAL.name,
    image1: 'sunmi-p2-se-01.png',
    image2: 'sunmi-p2-se-02.png',
    image3: 'sunmi-p2-se-03.png',
    image4: 'sunmi-p2-se-04.png',
    image5: 'sunmi-p2-se-05.png',
    productCatalog: 'sbisolution-sunmi-p2-se.pdf',
    description:
      'SUNMI P2 SE adalah solusi pembayaran terpercaya yang dirancang untuk memenuhi kebutuhan bisnis Anda. Dengan SUNMI P2 SE, Anda dapat menerima pembayaran dengan mudah dan aman. Dilengkapi dengan fitur-fitur canggih seperti layar sentuh yang responsif, kamera berkualitas tinggi, dan konektivitas yang luas, SUNMI P2 SE memastikan pengalaman pembayaran yang lancar dan efisien. Dapatkan SUNMI P2 SE secara online di Solusi Bersama Informatika dan tingkatkan kemudahan pembayaran dalam bisnis Anda dengan solusi pembayaran terpercaya',
    specifications: {
      'Product Model': 'T6820',
      OS: 'SUNMI OS (based on Android 11 Go)',
      Processor: 'Cortex-A53 quad-core 2.0GHz',
      Memory: '1GB RAM+8GB ROM, 2GB RAM+16GB ROM (optional)',
      Display: '5"FW+, 960*480, 5"HD, 1280*720, IPS (optional)',
      Camera: 'Rear: 0.3MP, FF, 2MP, FF (Optional), support 1D/2D barcode scanning',
      'IC Card':
        'Smart IC card/logic encryption contact card; ISO 7816/EMV/PBOC 3.0 compliant; 5V/3V/1.8V',
      'Magstripe Card': '1/2/3 track; Bi-directional card reading; ISO 7810/7811 compliant',
      'NFC Reader': 'Type A&B card, MIFARE card, ISO/IEC 14443 and ISO 15693 compliant',
      eSIM: '(Optional)',
      Fingerprint: '(Optional)',
      Scanner: '2D barcode scan engine (optional)',
      Speaker: '1* 1W',
      Network: '2G&3G&4G',
      Printer:
        'Built-in 58mm thermal printer, Print speed: 70mm/s, Paper roll: Φ40mm, Printer life: 100km',
      'Card Slot': '2 SIMs + 1PSAM or 1 SIM + 2 PSAMs, MicroSD(TF) up to 32G',
      'Wi-Fi': '2.4GHz/5GHz, IEEE 802.11 a/b/g/n',
      Bluetooth: 'Bluetooth 5.0, BLE',
      GPS: 'GPS, AGPS, Beidou, Galileo',
      Buttons: '1*Power on/off button, 1*Volume (+/-) button, Scan button(left/right) (optional)',
      Ports: '1* Type-C USB OTG supported, 1* Pogo pin on the back for charging (Optional)',
      Battery: 'Removable lithium battery, 7.2V / 2600mAh',
      'Power Adapter': 'Input: AC 100 ~ 240V, Output: DC 5V / 2A, DC 5V / 1A (optional)',
      'Dimensions (L*W*H)': '213.7*82*29mm',
      'Total Weight': '465g',
      Environment: 'Operating temp: -10°C～50°C, Storage temp: -20°C～60°C',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['sunmi-p2', 'sunmi-p2-pro'],
  },
  {
    name: 'SUNMI T2',
    slug: 'sunmi-t2',
    metaTitle: 'Jual Mesin Kasir SUNMI T2 Terbaik | Solusi Bersama Informatika',
    metaDescription:
      'Dapatkan mesin kasir SUNMI T2 terbaik dengan fitur lengkap dan kinerja terpercaya. Solusi Bersama Informatika menyediakan mesin kasir modern untuk bisnis Anda. Pesan sekarang!',
    metaKeywords:
      'SUNMI, SUNMI T2, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.DESKTOP_TERMINAL.name,
    image1: 'sunmi-t2-01.png',
    image2: 'sunmi-t2-02.png',
    image3: 'sunmi-t2-03.png',
    image4: 'sunmi-t2-04.png',
    image5: 'sunmi-t2-05.png',
    productCatalog: 'sbisolution-sunmi-t2.pdf',
    description:
      'Jual Mesin Kasir SUNMI T2 dengan Performa Terbaik di Solusi Bersama Informatika. Dorong efisiensi dan produktivitas bisnis Anda dengan mesin kasir SUNMI T2 yang canggih dan handal. Didesain untuk memenuhi kebutuhan ritel modern, SUNMI T2 hadir dengan fitur-fitur terbaik dalam industri, memungkinkan Anda untuk mengelola transaksi dengan cepat dan akurat.Dengan memilih mesin kasir SUNMI T2 dari Solusi Bersama Informatika, Anda akan mendapatkan solusi kasir yang tangguh dan handal untuk membantu mengoptimalkan proses transaksi di toko atau bisnis Anda. Segera hubungi kami untuk informasi lebih lanjut atau pesan sekarang!',
    specifications: {
      'Model 01 Display 15.6"+15.6"':
        'Main screen: 15.6" FHD, 1920×1080; Secondary screen: 15.6" FHD, 1920×1080',
      'Model 02 Display 15.6"+10.1"':
        'Main screen: 15.6" FHD, 1920×1080; Secondary screen: 10.1" FHD, 1024×600',
      'Model 03 Display 15.6"': 'Main screen: 15.6" FHD, 1920×1080',
      OS: 'Android OS\nSUNMI OS',
      CPU: 'High performance octa-core processor',
      Memory: '2GB RAM + 16GB ROM',
      'Touch Screen': 'G+G capacitive multi-touch screen',
      'Power Adapter': 'Input: AC100~240V/1.7A; Output: DC24V/2.5A',
      'Wi-Fi': '2.4G/5G support IEEE 802.11 a/b/g/n',
      Bluetooth: 'Support Bluetooth 2.1/3.0/4.0, Support BLE',
      Dimensions: '407mm*382mm*232mm',
      'Speaker/MIC': '1.2W',
      Printer: 'Automatic cutter: Equipped, Print speed: 200 mm/s, Paper roll: 80mm OD',
      'External Memory Card': 'Support MicroSD (TF) up to 64Gb',
      Environment: 'Operating temp: 0°C～40°C; Storage temp: -20°C～60°C',
      'External Port':
        'USB Type A port × 5, RJ11 serial port × 1, RJ12 cash drawer port × 1, RJ45 LAN port × 1, Audio jack × 1, Power port × 1, Micro-USB debug port × 1',
    },
    additionalContent: null,
    showInFooter: false,
    relatedProducts: ['sunmi-t2s', 'imin-d4-series', 'imin-d3-series', 'imin-swan-1'],
  },
  {
    name: 'SUNMI T2 MINI',
    slug: 'sunmi-t2-mini',
    metaTitle:
      'Mesin Kasir SUNMI T2 MINI Solusi Modern untuk Bisnis Anda | Solusi Bersama Informatika',
    metaDescription:
      'Temukan mesin kasir SUNMI T2 MINI yang canggih dan handal di Solusi Bersama Informatika. Dapatkan solusi modern untuk bisnis Anda dengan fitur-fitur unggulan dan performa tinggi',
    metaKeywords:
      'SUNMI, SUNMI T2 MINI, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.DESKTOP_TERMINAL.name,
    image1: 'sunmi-t2-mini-01.png',
    image2: 'sunmi-t2-mini-02.png',
    image3: 'sunmi-t2-mini-03.png',
    image4: 'sunmi-t2-mini-04.png',
    image5: 'sunmi-t2-mini-05.png',
    productCatalog: 'sbisolution-sunmi-t2-mini.pdf',
    description:
      'Optimalkan operasional bisnis Anda dengan mesin kasir SUNMI T2 MINI yang inovatif. Didesain khusus untuk memberikan solusi modern bagi bisnis Anda, mesin kasir ini memadukan keandalan, performa tinggi, dan fitur-fitur unggulan yang dapat meningkatkan efisiensi transaksi. Dilengkapi dengan sistem operasi Android yang fleksibel, SUNMI T2 MINI menawarkan pengalaman pengguna yang intuitif dan mudah. Prosesor octa-core bertenaga tinggi memberikan kecepatan dan responsivitas yang optimal, sementara RAM 2GB dan ROM 16GB memungkinkan penyimpanan data yang cukup besar. Dapatkan mesin kasir SUNMI T2 MINI yang handal ini hanya di Solusi Bersama Informatika. Tingkatkan efisiensi bisnis Anda dan hadirkan solusi modern untuk kesuksesan usaha Anda',
    specifications: {
      'Model Approved':
        'Config 1: L1323 (80mm printer +WIFI)\nConfig 2: L1323 (80mm printer + NFC + Scanner+4G)',
      OS: 'Android OS SUNMI OS',
      CPU: 'Cortex-A53 octa-core 1.4GHz',
      Memory: '2GB RAM + 16GB ROM',
      Display:
        'Capacitive multitouch\nMain Display：11.6" FHD 1920*1080\nSecondary Display: LED Display, FSTN ,128*40',
      Camera: '0.3MP, Supports 1D/2D barcode recognition (Config 2)',
      Printer:
        'Built-in 80mm thermal printer, Auto cutter: equipped, Print speed: 160mm/s, Service life: 100Km, Paper roll: Φ60mm',
      'NFC Card Reader': 'Supports Type A&B card, Mifare card, Felica card ISO/ICE 14443 compliant',
      Scanner: 'Supports 1D/2D barcode recognition (Config 2)',
      Speaker: '1.2W',
      'SIM Card Slot': '1 x MINI SIM (Config 2)',
      Network: '2G & 3G & 4G (Config 2)',
      'Wi-Fi': '2.4GHz Supports IEEE 802.11 b/g/n',
      Bluetooth: 'Supports Bluetooth 2.1/3.0/4.2\nSupports BLE',
      'Wired Network': 'LAN 100M',
      'TF Card': '1 x MicroSD (TF) Up to 64Gb',
      Ports:
        '4 x USB Type A port, 1 x RJ11 serial port, 1 x RJ12 cash drawer port, 1 x RJ45 LAN port, 1 x Audio jack, 1 x Power port, 1 x Micro USB debug port',
      'Power Adapter': 'Input: AC100~240V/1.7A; Output: DC24V/2.5A',
      'Dimensions (L*W*H)': '294*303*107mm',
      'Total Weight': '2.0Kg',
      Environment: 'Operating temperature: 0°C~40°C; Storage temperature: -20°C~60°C',
    },
    additionalContent: null,
    showInFooter: false,
    relatedProducts: ['sunmi-d2-mini', 'imin-falcon-1', 'imin-d1-series', 'imin-m2-max'],
  },
  {
    name: 'SUNMI T2s',
    slug: 'sunmi-t2s',
    metaTitle: 'Mesin Kasir SUNMI T2s Solusi Modern untuk Bisnis Anda | Solusi Bersama Informatika',
    metaDescription:
      'Temukan mesin kasir SUNMI T2s terbaik untuk bisnis Anda. Dapatkan kecepatan, keandalan, dan fungsionalitas yang luar biasa. Hubungi Solusi Bersama Informatika sekarang!',
    metaKeywords:
      'SUNMI, SUNMI T2s, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.DESKTOP_TERMINAL.name,
    image1: 'sunmi-t2s-01.png',
    image2: 'sunmi-t2s-02.png',
    image3: 'sunmi-t2s-03.png',
    image4: 'sunmi-t2s-04.png',
    image5: 'sunmi-t2s-05.png',
    productCatalog: 'sbisolution-sunmi-t2s.pdf',
    description:
      'SUNMI T2s adalah solusi kasir modern yang memberikan pengalaman pembayaran yang efisien dan praktis untuk bisnis Anda. Dengan desain elegan dan fitur canggih, mesin kasir ini menjadi pilihan terbaik untuk meningkatkan produktivitas dan efektivitas operasional. Ditenagai oleh prosesor canggih Kryo-260 Octa-core 2.2GHz, SUNMI T2s menawarkan kinerja yang cepat dan responsif, dilengkapi dengan RAM 3GB dan ROM 32GB, mesin kasir ini dapat menyimpan data dan aplikasi dengan mudah. SUNMI T2s adalah pilihan terbaik untuk bisnis Anda dalam meningkatkan efisiensi dan kualitas layanan pelanggan. Dapatkan mesin kasir berkualitas tinggi dari Solusi Bersama Informatika sekarang juga!',
    specifications: {
      'Configuration 1': '15.6" FHD, 1920*1080 Capacitive Touch Screen',
      'Configuration 2':
        'Main: 15.6" FHD, 1920*1080 Capacitive Touch Screen\nSecond: 10.1"HD, 1024*600 Capacitive Touch Screen',
      'Configuration 3':
        'Main: 15.6" FHD, 1920*1080 Capacitive Touch Screen\nSecond: 15.6" FHD, 1920*1080 Capacitive Touch Screen',
      OS: 'SUNMI OS (*based on Android 9.0)',
      CPU: 'Kryo-260 Octa-core up to 2.2GHz',
      Memory: '3GB RAM + 32GB ROM / 4GB RAM + 64GB ROM (optional)',
      Printer: 'Paper roll width: 80mm, Diameter: 80mm, Speed: 250mm/s, Support Automatic Cutter',
      Speaker: '1 x 1.2W',
      'SIM Card Slot': '1 x Micro SIM (optional)',
      Network: '2G&3G&4G (optional)',
      'Wi-Fi': '2.4GHz/5GHz IEEE 802.11 a/b/g/n /ac',
      Bluetooth: 'BT2.1/3.0/4.0/5.0, BLE',
      LAN: 'LAN 100M',
      'External Storage Card': 'Support Micro SD (TF) Up to 64GB',
      Interface:
        '5 x USB TypeA, 1 x RJ11 Serial port, 1 x RJ12 Cash drawer port, 1 x RJ45 LAN port, 1 x Audio jack, 1 x Power port, 1 x Micro-USB (debug)',
      'Power Adapter': 'Input: AC100~240V/1.7A; Output: DC24V/2.5A',
      Dimensions: '407*382*232mm (max)',
      Weight: 'Configuration1: 5.1 Kg\nConfiguration2: 5.7 Kg\nConfiguration3: 7.4 Kg',
      Environment: 'Operating temperature: 0°C ~ 40°C; Storage temperature: -20°C ~ 60°C',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['sunmi-t2', 'imin-d4-series', 'imin-d3-series', 'imin-swan-1'],
  },
  {
    name: 'SUNMI D2 MINI',
    slug: 'sunmi-d2-mini',
    metaTitle:
      'Mesin Kasir SUNMI D2 MINI Solusi Modern untuk Bisnis Anda | Solusi Bersama Informatika',
    metaDescription:
      'Temukan mesin kasir SUNMI D2 MINI terbaik untuk bisnis Anda. Dapatkan kecepatan, keandalan, dan kemudahan penggunaan yang luar biasa. Hubungi Solusi Bersama Informatika sekarang!',
    metaKeywords:
      'SUNMI, SUNMI D2 MINI, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.DESKTOP_TERMINAL.name,
    image1: 'sunmi-d2-mini-01.png',
    image2: 'sunmi-d2-mini-02.png',
    image3: 'sunmi-d2-mini-03.png',
    image4: 'sunmi-d2-mini-04.png',
    image5: 'sunmi-d2-mini-05.png',
    productCatalog: 'sbisolution-sunmi-d2-mini.pdf',
    description:
      'SUNMI D2 MINI adalah mesin kasir revolusioner yang memadukan performa tinggi dengan desain kompak. Dirancang khusus untuk bisnis Anda, mesin kasir ini memberikan pengalaman pembayaran yang efisien dan lancar. Dengan ukuran yang ringkas, SUNMI D2 MINI tidak memakan banyak ruang pada area kasir Anda, memungkinkan tata letak yang fleksibel dan nyaman, layar sentuh 10.1" IPS HD memberikan pengalaman interaksi yang intuitif dan responsif, mempermudah operasional sehari-hari. SUNMI D2 MINI memberikan solusi kasir yang praktis, andal, dan efisien untuk bisnis Anda. Dapatkan mesin kasir berkualitas tinggi dari Solusi Bersama Informatika sekarang dan tingkatkan produktivitas usaha Anda!',
    specifications: {
      OS: 'SUNMI OS (based on Android 8.1)',
      CPU: 'Quad-core Processor',
      Memory: '2GB RAM + 8GB ROM',
      Display:
        'Main Screen: 10.1" HD, 1280x800 Capacitive Touch Screen; Customer Display: 4.3" WVGA 800x480',
      'Wi-Fi': '2.4GHz Support IEEE 802.11 b/g/n',
      Bluetooth: 'Support BT 2.1/3.0/4.2, Support BLE',
      Speaker: '3W 4Ω',
      NFC: 'Support ISO14443 A, ISO14443 B, ISO 15693, MIFARE 1K/4K',
      'PSAM Card': 'Support Mini SIM(2FF) Card',
      'External Storage Card': 'Support MicroSD Card up to 64GB',
      'Scan Code Module':
        'QR code, Accuracy > 10mil, Speed < 0.05s, 30fps (Only available with the scanning version)',
      'Membership Camera': '5M FF, USB port (Only available with the scanning version)',
      Printer:
        'Built in 58mm thermal printer, Print speed: 160mm/s, Print life: 50km, Support till rolls of 60mm OD',
      'IM Card Socket':
        '1.8V/3.0V Micro SIM(3FF)\n(4G network is only available with the printer version)',
      'External Interface':
        '5 × USB Type-A port, 1 × RJ11 Serial port, 1 × RJ12 Cash box port, 1 × RJ45 LAN port, 1 × Audio jack, 1 × Power port, 1 × Micro USB debug port',
      'Power Adapter': 'Input: AC100~240V/1.7A; Output: DC24V/2.5A (Printer Version)',
      'Dimensions (LxWxH)': '260 x 290 x 99mm',
      Weight: 'Scanning Version: 1.81kg; Printer Version: 1.94kg',
      Environment: 'Working temperature: 0°C - 40°C; Storage temperature: -20°C - 60°C',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['sunmi-t2-mini', 'imin-falcon-1', 'imin-d1-series', 'imin-m2-max'],
  },
  {
    name: 'SUNMI S2',
    slug: 'sunmi-s2',
    metaTitle: 'Mesin Kasir SUNMI S2 Solusi Modern untuk Bisnis Anda | Solusi Bersama Informatika',
    metaDescription:
      'Temukan mesin kasir SUNMI S2 terbaik untuk bisnis Anda. Dapatkan kecepatan, keandalan, dan kemudahan penggunaan yang luar biasa. Hubungi Solusi Bersama Informatika sekarang!',
    metaKeywords:
      'SUNMI, SUNMI S2, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.DESKTOP_TERMINAL.name,
    image1: 'sunmi-s2-01.png',
    image2: 'sunmi-s2-02.png',
    image3: 'sunmi-s2-03.png',
    image4: 'sunmi-s2-04.png',
    image5: 'sunmi-s2-05.png',
    productCatalog: 'sbisolution-sunmi-s2.pdf',
    description:
      'SUNMI S2 adalah mesin kasir canggih yang dirancang untuk memenuhi kebutuhan sistem operasional bisnis Anda. Dengan kombinasi keandalan tinggi dan performa yang tak tertandingi, SUNMI S2 adalah pilihan terbaik untuk mengoptimalkan operasional kasir Anda, dilengkapi dengan timbangan yang langsung terhubung dengan kasir jadi sangat cocok untuk Anda yang menjual fresh product seperti daging, sayur, dan buah-buahan. Dengan layar sentuh 15.6" Full HD, SUNMI S2 menyajikan tampilan yang jelas dan intuitif, memastikan pengalaman pengguna yang optimal, dilengkapi dengan prosesor Octa-core 2.2GHz, mesin kasir ini menawarkan kinerja yang cepat dan responsif, sehingga memungkinkan pemrosesan transaksi yang efisien. Dengan SUNMI S2, Anda dapat meningkatkan efisiensi dan keandalan sistem pembayaran bisnis Anda. Percayakan solusi mesin kasir terbaik dari Solusi Bersama Informatika dan rasakan manfaatnya sekarang!',
    specifications: {
      'Model 1 - 15.6"+15.6"':
        'Main screen: 15.6" FHD, 1920×1080\nCustomer screen: 15.6" FHD, 1920×1080',
      'Model 2 - 15.6"+10.1"':
        'Main screen: 15.6" FHD, 1920×1080\nCustomer screen: 10.1" HD, 1024×600',
      'Model 3 - 15.6"': 'Main screen: 15.6" FHD, 1920×1080',
      CPU: 'Octa-core Qualcomm Snapdragon',
      OS: 'SUNMI OS',
      Memory: '2GB RAM + 16GB ROM',
      'Touch Screen': 'G+G capacitive multi-touch screen',
      'Power Adapter': 'INPUT：AC100~240V/1.7A, OUTPUT：DC24V/2.5A',
      'Wi-Fi': '2.4G/5G Support IEEE 802.11 a/b/g/n',
      Bluetooth: 'Support Bluetooth 2.1/3.0/4.0',
      Range: '40g - 6/15kg (dual range)',
      'Speaker/MIC': '3W',
      'External Memory Card': 'Support 1 x MicroSD (TF) up to 64GB',
      Environment: 'Work temp: 0°C~40°C; Storage temp:-20°C~60°C',
      Printer: 'Built-in High Speed 80 thermal printer with automatic cutter, Print: speed 200mm/s',
      'External Port':
        '5 x USB Type A, 1 x RJ11 serial port, 1 x RJ12 cash drawer port, 1 x RJ45 LAN port, 1 x Headphone jack, 1 x Power Port, 1 x Micro-USB debug port',
    },
    additionalContent: null,
    showInFooter: false,
    relatedProducts: ['imin-d1w'],
  },
  {
    name: 'SUNMI K2',
    slug: 'sunmi-k2',
    metaTitle: 'Mesin Kasir SUNMI K2 Solusi Modern untuk Bisnis Anda | Solusi Bersama Informatika',
    metaDescription:
      'SUNMI K2, mesin kasir mandiri terbaik untuk kebutuhan bisnis Anda. Dapatkan kecepatan, keandalan, dan kemudahan penggunaan dalam satu solusi kiosk yang canggih. Hubungi Solusi Bersama Informatika sekarang!',
    metaKeywords:
      'SUNMI, SUNMI K2, kiosk, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.KIOSK.name,
    image1: 'sunmi-k2-01.png',
    image2: 'sunmi-k2-02.png',
    image3: 'sunmi-k2-03.png',
    image4: 'sunmi-k2-04.png',
    image5: 'sunmi-k2-05.png',
    productCatalog: 'sbisolution-sunmi-k2.pdf',
    description:
      'SUNMI K2 adalah solusi mesin kasir mandiri yang memadukan kecepatan, keandalan, dan kemudahan penggunaan dalam satu perangkat kiosk yang canggih. Dirancang khusus untuk memenuhi kebutuhan sistem pembayaran modern, SUNMI K2 memberikan pengalaman transaksi yang mulus dan efisien. Dengan desain yang elegan dan fungsional, SUNMI K2 cocok untuk berbagai lingkungan bisnis, seperti restoran, toko ritel, atau pusat perbelanjaan, dilengkapi dengan layar sentuh 24" Full HD, SUNMI K2 memberikan pengalaman interaktif yang nyaman bagi pengguna, memungkinkan navigasi intuitif dan transaksi yang cepat. Dengan SUNMI K2, Anda dapat meningkatkan efisiensi operasional, meningkatkan pengalaman pelanggan, dan mengoptimalkan sistem pembayaran di bisnis Anda. Hubungi Solusi Bersama Informatika sekarang untuk informasi lebih lanjut!',
    specifications: {
      OS: 'SUNMI OS',
      CPU: 'Six-core 1.8GHz',
      Memory: '4G RAM + 16G ROM, Support MicroSD(TF) up to 128GB',
      Display: '24" 1080×1920 FHD',
      Camera: '3D structured light camera\nSupport FR payment, Membership Verification, etc.',
      Printer:
        '80-column thermal printer with automatic cutter, Support 80mm OD paper roll, Print speed up to 150mm/s',
      Speaker: '2 x 3W (stereo)',
      'Wi-Fi': 'IEEE 802.11 a/b/g/n 2.4/5GHz',
      Ethernet: '10/100/1000M Ethernet',
      Interface: '1 × Micro-USB Debug Port, 5 × USB TypeA port',
      Dimensions:
        'Wall-mounted: 394x171x1017mm; Floor-standing with table: 1018x645x1850mm; Floor-standing: 540x490x1850mm',
      'Total Weight': 'Wall-mounted: 16.5Kg; Floor-standing with table: 58Kg; Floor-standing: 45Kg',
      'Power Adapter': 'AC100~120V\nAC200~240V',
      Environment: 'Working temperature: 0°C ~ 40°C; Storage temperature: -20°C ~ 55°C',
    },
    additionalContent: null,
    showInFooter: false,
    relatedProducts: ['sunmi-k2-mini', 'sunmi-k1'],
  },
  {
    name: 'SUNMI K2 MINI',
    slug: 'sunmi-k2-mini',
    metaTitle:
      'SUNMI K2 MINI Mesin Kasir Mandiri Kompak untuk Solusi Pembayaran Modern | Solusi Bersama Informatika',
    metaDescription:
      'SUNMI K2 MINI mesin kasir mandiri kompak yang memberikan solusi pembayaran modern yang efisien. Dapatkan kecepatan, kehandalan, dan kemudahan penggunaan dalam satu perangkat kiosk canggih. Hubungi Solusi Bersama Informatika sekarang!',
    metaKeywords:
      'SUNMI, SUNMI K2 MINI, kiosk, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.KIOSK.name,
    image1: 'sunmi-k2-mini-01.png',
    image2: 'sunmi-k2-mini-02.png',
    image3: 'sunmi-k2-mini-03.png',
    image4: 'sunmi-k2-mini-04.png',
    image5: 'sunmi-k2-mini-05.png',
    productCatalog: 'sbisolution-sunmi-k2-mini.pdf',
    description:
      'SUNMI K2 MINI adalah solusi mesin kasir mandiri yang kompak dan canggih, dirancang khusus untuk memenuhi kebutuhan sistem pembayaran modern. Meskipun ukurannya yang kecil, SUNMI K2 MINI tetap memberikan kecepatan, kehandalan, dan kemudahan penggunaan yang optimal. Dengan desain yang kompak dan ergonomis, SUNMI K2 MINI dapat ditempatkan di ruang yang terbatas tanpa mengorbankan fungsionalitasnya. Ideal untuk toko kecil, usaha kuliner, atau bisnis ritel dengan ruang terbatas, dilengkapi dengan layar sentuh 15.6" beresolusi tinggi, SUNMI K2 MINI memberikan pengalaman interaktif yang lancar bagi pengguna, memungkinkan navigasi intuitif dan transaksi yang cepat. Dengan SUNMI K2 MINI, Anda dapat mengoptimalkan proses pembayaran, meningkatkan efisiensi operasional, dan memberikan pengalaman pelanggan yang lebih baik. Hubungi Solusi Bersama Informatika sekarang untuk informasi lebih lanjut tentang solusi kiosk ini!',
    specifications: {
      OS: 'SUNMI OS (based on Android OS)',
      CPU: 'Qualcomm Snapdragon Octa-core / Rockchip Quad-core',
      Memory: '2GB RAM + 16GB ROM, Support MicroSD(TF) up to 64GB',
      Display:
        'Portrait screen: 15.6" 1080x1920 FHD Multi touch Screen; Landscape (optional): 15.6" 1920x1080 FHD Multi touch Screen',
      Camera: '3D structured light camera; Support FR payment, membership verification, etc.',
      Scanner: 'Support 1D, 2D mobile screen and printed barcodes',
      NFC: '2nd generation ID card reading, Support M1/ID/CPU card reading & writing (an option for Extendable Version)',
      Printer:
        'Thermal printer with automatic cutter, Support 58/80mm OD paper roll, Print speed up to 250mm/s',
      Speaker: '1 x 3W',
      'Wi-Fi': '2.4GHz/5GHz IEEE 802.11 a/b/g/n/ac',
      Bluetooth: 'Support Bluetooth 2.1/3.0/4.0, Support BLE',
      Ethernet: '1 x 10/100M self-adaptive',
      Interface:
        '5 × USB Type-A ports, 1 × RJ11 Serial port, 1 × RJ12 Cash drawer port, 1 × RJ45 LAN port, 1 × Audio jack, 1 × Power port, 1 × Micro USB debug port',
      Dimensions:
        'Dual monitor: 380x280x590mm; Single monitor: 300x250x590mm; Wall-mounted: 265x120x680mm',
      'Total Weight': 'Dual monitor: 10.5Kg; Single monitor: 8Kg, Wall-mounted: 5Kg',
      'Power Adapter': 'Input: AC100~240V/1.7A; Output: DC24V/2.5A',
      Environment: 'Operating temperature: 0 ~ 40°C; Storage temperature: -20°C ~ 55°C',
    },
    additionalContent: null,
    showInFooter: false,
    relatedProducts: ['sunmi-k2', 'sunmi-k1'],
  },
  {
    name: 'SUNMI K1',
    slug: 'sunmi-k1',
    metaTitle:
      'SUNMI K1 Solusi Kiosk Mandiri untuk Pengalaman Interaktif yang Modern | Solusi Bersama Informatika',
    metaDescription:
      'SUNMI K1 solusi kiosk mandiri yang menghadirkan pengalaman interaktif yang canggih. Hadir dengan fitur-fitur terdepan dalam industri, pilihan sempurna untuk mengoptimalkan bisnis Anda. Hubungi Solusi Bersama Informatika sekarang!',
    metaKeywords:
      'SUNMI, SUNMI K1, kiosk, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SUNMI.name,
    category: PRODUCT_CATEGORIES.KIOSK.name,
    image1: 'sunmi-k1-01.png',
    image2: 'sunmi-k1-02.png',
    image3: 'sunmi-k1-03.png',
    image4: 'sunmi-k1-04.png',
    image5: 'sunmi-k1-05.png',
    productCatalog: 'sbisolution-sunmi-k1.pdf',
    description:
      'SUNMI K1 adalah kiosk mandiri inovatif yang menghadirkan pengalaman interaktif yang canggih untuk berbagai kebutuhan bisnis. Dengan kombinasi antara desain yang elegan, performa tinggi, dan keamanan yang handal, SUNMI K1 menjawab tantangan bisnis modern dengan solusi yang terdepan dalam industri. Ditenagai oleh prosesor Quad-core 1.8GHz dan RAM 2GB, kiosk ini menawarkan kinerja tinggi yang dapat mengatasi tugas-tugas berat dengan mudah',
    specifications: {
      OS: 'SUNMI OS',
      CPU: 'Rockchip Quad-core',
      Memory: '2GB RAM + 16GB ROM',
      Camera: '3D light-weight HD camera',
      'Barcode Reader': 'Reads 1D, 2D mobile-phone screens and printed barcodes',
      Printer:
        '80mm thermal printer with Automatic cutter, Equipped Till roll OD: 80mm, Print speed: 150mm/s',
      'External Memory Card': 'Supports MicroSD(TF) up to 64G',
      Speaker: '2x3W (one on each side)',
      'Wi-Fi': 'supports IEEE 802.11b/g/n',
      Ethernet: '1 x 10/100/1000M adaptive',
      Port: '1 x Micro-USB port, 4 x USB Type A port',
      Dimensions: 'Wall-mounted: 36x11x82cm; Floor-standing: 71x63x166cm',
      'Total Weight': 'Wall-mounted: 17.6kg; Floor-standing: 64.8kg',
      'Power Adapter': 'AC100~120V; AC200~240V',
      Environment: 'Operating temperature: 0~40°C; Storage temperature: -20~55°C',
    },
    additionalContent: null,
    showInFooter: false,
    relatedProducts: ['sunmi-k2', 'sunmi-k2-mini'],
  },
  {
    name: 'iMin FALCON 1',
    slug: 'imin-falcon-1',
    metaTitle:
      'iMin Falcon 1 Mesin Kasir Modern dengan Performa Terbaik | Solusi Bersama Informatika',
    metaDescription:
      'iMin Falcon 1, mesin kasir modern dengan performa terbaik untuk bisnis Anda. Dengan fitur-fitur canggih dan desain yang elegan, iMin Falcon 1 menjadi solusi ideal untuk meningkatkan efisiensi dan pengalaman transaksi pelanggan. Hubungi Solusi Bersama Informatika sekarang!',
    metaKeywords:
      'iMin, iMin FALCON 1, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IMIN.name,
    category: PRODUCT_CATEGORIES.DESKTOP_TERMINAL.name,
    image1: 'imin-falcon-1-01.png',
    image2: 'imin-falcon-1-02.png',
    image3: 'imin-falcon-1-03.png',
    image4: 'imin-falcon-1-04.png',
    image5: 'imin-falcon-1-05.png',
    productCatalog: 'sbisolution-imin-falcon-1.pdf',
    description:
      'iMin Falcon 1 adalah mesin kasir modern yang menghadirkan performa terbaik untuk bisnis Anda. Dengan desain yang elegan dan fitur-fitur canggih, iMin Falcon 1 menjadi solusi kasir yang ideal untuk meningkatkan efisiensi operasional dan pengalaman transaksi pelanggan. Ditenagai oleh prosesor yang kuat dan RAM yang memadai, iMin Falcon 1 mampu mengolah transaksi dengan cepat dan efisien, mempercepat layanan pelanggan Anda. Dengan desain yang elegan dan ringkas, iMin Falcon 1 tidak hanya mempercantik tempat usaha Anda, tetapi juga menghemat ruang dan mudah untuk ditempatkan. Hubungi Solusi Bersama Informatika sekarang untuk informasi lebih lanjut tentang mesin kasir ini dan bagaimana iMin Falcon 1 dapat membantu bisnis Anda.',
    specifications: {
      Layar: '6.5" 720×1600',
      Memory: 'RAM: 2GB, ROM: 16GB; RAM: 4GB, ROM: 32GB',
      Ukuran: '225.4 x 86.5 x 66.4 mm',
      'Sambungan Periferal': 'Nano SIM x 1, TF Card x 1, USB Type C x 1, Pogo Pin (6 Pin)',
      Processor: 'Octa-Core, 8x A55, 1.66GHz',
      'Operating System': 'Android 11 Series iMin UI',
      'Layar Sentuh': 'Multi-point Capacitive Touch Panel',
      Koneksi: 'Wi-Fi: 802.11 a/b/g/n/ac (2.4 Ghz/5 GHz); Bluetooth: 5.0, NFC',
      'Sumber Daya': 'Input: 5V = 2A',
      Speaker: '0.8W Mono',
      Kamera: '0.8MP Front Camera, 5MP Rear Camera',
      Berat: 'PDA: 245g, Swift 1p: 380g, Swift 1s: 290g',
      Sertifikasi: 'FCC CE IMDA WEEE',
      Printer: 'Fast print speed up to 100mm/s, Support paper rolls of 58mm width & 40mm diameter',
      Scanner: '1D/QR Code',
      Baterai: '7.6V 2550mAH',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['imin-d1-series', 'sunmi-d2-mini', 'sunmi-t2-mini', 'imin-m2-max'],
  },
  {
    name: 'iMin SWAN 1',
    slug: 'imin-swan-1',
    metaTitle: 'iMin Swan 1 Mesin Kasir Modern dengan Desain Elegan | Solusi Bersama Informatika',
    metaDescription:
      'iMin Swan 1 mesin kasir modern dengan desain elegan yang menghadirkan solusi kasir terbaik untuk bisnis Anda. Tingkatkan efisiensi transaksi dan pengalaman pelanggan dengan fitur-fitur canggih iMin Swan 1. Hubungi Solusi Bersama Informatika sekarang!',
    metaKeywords:
      'iMin, iMin SWAN 1, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IMIN.name,
    category: PRODUCT_CATEGORIES.DESKTOP_TERMINAL.name,
    image1: 'imin-swan-1-01.png',
    image2: 'imin-swan-1-02.png',
    image3: 'imin-swan-1-03.png',
    image4: 'imin-swan-1-04.png',
    image5: 'imin-swan-1-05.png',
    productCatalog: 'sbisolution-imin-swan-1.pdf',
    description:
      'iMin Swan 1 adalah mesin kasir modern dengan desain elegan yang memberikan solusi kasir terbaik untuk bisnis Anda. Dibangun dengan fitur-fitur canggih, iMin Swan 1 memungkinkan Anda meningkatkan efisiensi transaksi dan memberikan pengalaman pelanggan yang optimal. Dengan desain yang elegan dan modern, iMin Swan 1 akan mempercantik tampilan tempat usaha Anda, menciptakan kesan profesional dan menarik bagi pelanggan. Dengan iMin Swan 1, Anda dapat meningkatkan efisiensi transaksi, mempercepat layanan pelanggan, dan meningkatkan kepuasan pelanggan. Hubungi Solusi Bersama Informatika sekarang untuk informasi lebih lanjut tentang mesin kasir ini dan bagaimana iMin Swan 1 dapat menjadi solusi terbaik untuk bisnis Anda.',
    specifications: {
      Layar: 'Main Display: 15.6" 1920 x 1080; Secondary Display: 10.1" 1280 x 800',
      Memory: '2GB RAM + 16GB ROM; 4GB RAM + 32GB ROM',
      Ukuran: '363.8 x 185 x 300mm',
      'Sambungan Periferal':
        'Туpе_А 2.0 x 2, Туре_A 3.0 x 1, RJ12 x 1, RJ11 x 1, RJ45 x 1, 3.5mm Audio Jack x 1, DC Jack x 1, TF Card x 1, Port to Connect Secondary Display',
      Processor: 'Quad-Core ARM Cortex-A55, 2.0GHZ',
      'Operating System': 'Android 11 series iMin UI',
      'Layar Sentuh':
        'Multi–point Capacitive Touch Panel; Secondary Display (Sold Separately) : Multi-point Capacitive Touch Panel (Optional)',
      NFC: 'Optional',
      Koneksi: 'Ethernet: 1000M, Bluetooth 4.2; Wi-Fi: 802.11 a/b/g/n/ac',
      'Sumber Daya': 'Input: 100 – 240V, Output: 24V / 1.5A',
      Sertifikasi: 'FCC CE IMDA WEEE',
      Berat: 'Dual Display: 3.23Kg. Single Display: 2.65Kg',
      'TF Card': 'Up to 128Gb',
      Speaker: '1.5W Mono',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['imin-d3-series', 'imin-d4-series', 'sunmi-t2s', 'sunmi-t2'],
  },
  {
    name: 'iMin D4 Series',
    slug: 'imin-d4-series',
    metaTitle:
      'iMin D4 Series Mesin Kasir Canggih untuk Efisiensi Transaksi | Solusi Bersama Informatika',
    metaDescription:
      'iMin D4 series, mesin kasir canggih yang meningkatkan efisiensi transaksi bisnis Anda. Dapatkan kecepatan dan kemudahan dalam pengelolaan kasir dengan iMin D4. Hubungi Solusi Bersama Informatika sekarang!',
    metaKeywords:
      'iMin, iMin D4, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IMIN.name,
    category: PRODUCT_CATEGORIES.DESKTOP_TERMINAL.name,
    image1: 'imin-d4-series-01.png',
    image2: 'imin-d4-series-02.png',
    image3: 'imin-d4-series-03.png',
    image4: 'imin-d4-series-04.png',
    image5: 'imin-d4-series-05.png',
    productCatalog: 'sbisolution-imin-d4-series.pdf',
    description:
      'iMin D4 adalah mesin kasir canggih yang dirancang untuk meningkatkan efisiensi transaksi dan pengelolaan bisnis Anda. Dengan fitur-fitur unggulan, iMin D4 memberikan kecepatan dan kemudahan dalam proses kasir, sehingga Anda dapat fokus pada pertumbuhan bisnis Anda. Ditenagai oleh prosesor yang canggih, iMin D4 mampu mengolah transaksi dengan cepat dan akurat, meminimalkan waktu antrian pelanggan dan meningkatkan efisiensi kerja. Dengan iMin D4, Anda dapat meningkatkan efisiensi transaksi, mengelola stok dengan lebih baik, dan memberikan pengalaman yang lebih baik kepada pelanggan. Hubungi Solusi Bersama Informatika sekarang untuk informasi lebih lanjut tentang iMin D4 dan bagaimana mesin kasir ini dapat membantu bisnis Anda tumbuh dan berkembang.',
    specifications: {
      Layar: 'Main Display: 15.6″ 1920×1080; Secondary Display: 15.6″ 1920×1080',
      Memory: 'RAM: 2GB/4GB, ROM: 16GB/64GB',
      Ukuran: '380 x 195 x 407 mm',
      'Sambungan Periferal':
        'USB TypeA x 5, Micro USB x 1, RJ11 x 1, RJ12 x 1, RJ45 x 1, 3.5mm Earjack Port x 1, TF Card x 1',
      Processor: 'Quad-core ARM Cortex-A17 32-bit 1.6GHz',
      'Operating System': 'Android 11 iMin OS',
      'Layar Sentuh': 'Multi-point Capacitive Touch Panel',
      Koneksi: 'Ethernet: 100M, Wi-Fi: 802.11 b/g/n 2.4G, Bluetooth: BT4.2',
      'Sumber Daya': 'Input: 100-240V; Output: 24V 1.5A',
      Speaker: '1.5W Mono',
      Printer: 'Kecepatan 250mm/s dengan pemotong otomatis. Mendukung lebar kertas 58mm',
      Berat: '7 Kg',
      Sertifikasi: 'CE IMDA',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['sunmi-t2', 'sunmi-t2s', 'imin-swan-1', 'imin-d3-series'],
  },
  {
    name: 'iMin D3 Series',
    slug: 'imin-d3-series',
    metaTitle:
      'iMin D3 Series Mesin Kasir Canggih untuk Efisiensi Transaksi | Solusi Bersama Informatika',
    metaDescription:
      'iMin D3 series, mesin kasir modern yang meningkatkan efisiensi transaksi bisnis Anda. Dapatkan kecepatan dan kemudahan dalam pengelolaan kasir dengan iMin D3. Hubungi Solusi Bersama Informatika sekarang!',
    metaKeywords:
      'iMin, iMin D3, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IMIN.name,
    category: PRODUCT_CATEGORIES.DESKTOP_TERMINAL.name,
    image1: 'imin-d3-series-01.png',
    image2: 'imin-d3-series-02.png',
    image3: 'imin-d3-series-03.png',
    image4: 'imin-d3-series-04.png',
    productCatalog: 'sbisolution-imin-d3-series.pdf',
    description:
      'iMin D3 adalah mesin kasir modern yang dirancang untuk meningkatkan efisiensi transaksi dan pengelolaan bisnis Anda. Dengan fitur-fitur unggulan, iMin D3 memberikan kecepatan dan kemudahan dalam proses kasir, memungkinkan Anda untuk fokus pada pertumbuhan bisnis Anda. Dilengkapi dengan layar sentuh interaktif, iMin D3 memudahkan pengguna dalam mengoperasikan sistem kasir dan memproses transaksi dengan cepat. Pengguna dapat dengan mudah memilih produk, mengatur kuantitas, dan memproses pembayaran dengan mudah. Dengan iMin D3, Anda dapat meningkatkan efisiensi transaksi, mengelola stok dengan lebih baik, dan memberikan pengalaman yang lebih baik kepada pelanggan. Hubungi Solusi Bersama Informatika sekarang untuk informasi lebih lanjut tentang iMin D3 dan bagaimana mesin kasir ini dapat membantu bisnis Anda tumbuh dan berkembang',
    specifications: {
      Layar: 'Main Display : 15.6" 1920×1080; Secondary Display : 15.6" 1920×1280',
      Memory: '2GB RAM + 16GB ROM; 4GB RAM + 16GB ROM',
      Ukuran: '382 x 215 x 357 mm',
      'Sambungan Periferal':
        'USB Type-A x 4, Micro USB x 1, RJ12 x 1, RJ11 x 1, RJ45 x 1, 3.5mm Audio Jack x 1, DC Jack x 1, TF Card x 1',
      Processor: '4 x Cortex-A17, 1.6GHz',
      'Operating System': 'Android 11 iMin OS',
      'Layar Sentuh': 'Multi-point Capacitive Touch Panel',
      Koneksi: 'Ethernet: 100M, Wi-Fi: 802.11 b/g/n 2.4G, Bluetooth: BT4.2',
      'Sumber Daya': 'Input: 100-240V; Output: 24V 1.5A',
      Speaker: '1.5W Mono',
      Berat: '3.2kg',
      Sertifikasi: 'CE IMDA',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['imin-d4-series', 'imin-swan-1', 'sunmi-t2', 'sunmi-t2s'],
  },
  {
    name: 'iMin D2 Series',
    slug: 'imin-d2-series',
    metaTitle: 'iMin D2 Mesin Kasir Modern untuk Kelancaran Transaksi | Solusi Bersama Informatika',
    metaDescription:
      'iMin D2 series, mesin kasir modern yang mengoptimalkan kelancaran transaksi bisnis Anda. Dapatkan kecepatan dan kehandalan dalam pengelolaan kasir dengan iMin D2, hubungi Solusi Bersama Informatika sekarang!',
    metaKeywords:
      'iMin, iMin D2, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IMIN.name,
    category: PRODUCT_CATEGORIES.DESKTOP_TERMINAL.name,
    image1: 'imin-d2-series-01.png',
    image2: 'imin-d2-series-02.png',
    image3: 'imin-d2-series-03.png',
    image4: 'imin-d2-series-04.png',
    image5: 'imin-d2-series-05.png',
    productCatalog: 'sbisolution-imin-d2-series.pdf',
    description:
      'iMin D2 adalah mesin kasir modern yang dirancang untuk meningkatkan kelancaran transaksi bisnis Anda. Dengan fitur-fitur canggih, iMin D2 memberikan kecepatan dan kehandalan dalam proses kasir, memungkinkan Anda untuk menjalankan bisnis dengan lebih efisien. Ditenagai oleh teknologi terbaru, iMin D2 mampu memproses transaksi dengan cepat dan akurat, mengurangi waktu antrian pelanggan dan mempercepat layanan kasir. iMin D2 juga dapat terintegrasi dengan sistem pembayaran elektronik, printer struk, dan perangkat lainnya, meningkatkan efisiensi operasional dan memudahkan pengelolaan kasir Anda. Dengan iMin D2, Anda dapat meningkatkan kelancaran transaksi, mengelola stok dengan lebih efisien, dan memberikan pengalaman yang memuaskan kepada pelanggan. Hubungi Solusi Bersama Informatika sekarang untuk informasi lebih lanjut mengenai iMin D2 dan bagaimana mesin kasir ini dapat membantu pertumbuhan bisnis Anda.',
    specifications: {
      Layar: '10.1" 1280×800',
      Memory: 'RAM 1GB + ROM 8GB',
      Ukuran: '251.5 x 37 x 168 mm',
      'Sambungan Periferal':
        'USB Type A x 4, Micro USB x 1, 3.5mm Audio Jack x 1, RJ11 x 1, RJ12 x 1, RJ45 x 1, TF x 1, DC Jack x 1',
      Processor: 'Octa-Core ARM Cortex-A53 32-bit 1.5GHz',
      'Operating System': 'Android 7.1 iMin UI',
      'Layar Sentuh': 'Multi-point Capacitive Touch Panel',
      Koneksi: 'Ethernet: 100M, Wi-Fi: 802.11 b/g/n 2.4G, Bluetooth: BT4.2',
      'Sumber Daya': 'Input: 100-240V; Output: 24V 1.5A',
      Speaker: '1.5W Mono',
      'Penyimpanan Tambahan': 'TF Card up to 128GB',
      Berat: '0.7 Kg',
      Sertifikasi: 'CE IMDA',
    },
    additionalContent: null,
    showInFooter: false,
  },
  {
    name: 'iMin D1 Series',
    slug: 'imin-d1-series',
    metaTitle: 'iMin D1 Solusi Mesin Kasir Terbaik untuk Bisnis Anda | Solusi Bersama Informatika',
    metaDescription:
      'iMin D1 series, mesin kasir modern yang mengoptimalkan kelancaran transaksi bisnis Anda. Dapatkan kecepatan dan kehandalan dalam pengelolaan kasir dengan iMin D2, hubungi Solusi Bersama Informatika sekarang!',
    metaKeywords:
      'iMin, iMin D1, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IMIN.name,
    category: PRODUCT_CATEGORIES.DESKTOP_TERMINAL.name,
    image1: 'imin-d1-series-01.png',
    image2: 'imin-d1-series-02.png',
    image3: 'imin-d1-series-03.png',
    image4: 'imin-d1-series-04.png',
    image5: 'imin-d1-series-05.png',
    productCatalog: 'sbisolution-imin-d1-series.pdf',
    description:
      'iMin D1 merupakan solusi mesin kasir terbaik yang dirancang khusus untuk memenuhi kebutuhan bisnis Anda. Dengan fitur-fitur canggih dan kemudahan penggunaan, iMin D1 memberikan kecepatan, kehandalan, dan efisiensi dalam sistem pembayaran. iMin D1 dapat terintegrasi dengan perangkat lain seperti printer struk dan scanner barcode, meningkatkan efisiensi operasional dan mempercepat proses pembayaran. Dengan Imin D1, Anda akan mendapatkan solusi mesin kasir yang handal, efisien, dan dapat meningkatkan produktivitas bisnis Anda. Hubungi Solusi Bersama Informatika sekarang untuk informasi lebih lanjut mengenai Imin D1 dan bagaimana mesin kasir ini dapat membantu pertumbuhan bisnis Anda.',
    specifications: {
      Layar: '10.1" 1280×800 Display + 2.4" LED Screen',
      Memory: 'RAM: 2GB, ROM: 16GB',
      Ukuran: '250.2 x 168 x 80.5 mm',
      'Sambungan Periferal': 'Nano Sim x 2, Type-C x 1, Type-A x 2, RJ12 x 1, RJ45 x 1',
      Processor: 'Octa-Core 1.8GHz, Dual A75 & Hexa A55',
      'Operating System': 'Android 11 Go iMin UI',
      'Layar Sentuh': 'Multi-point Capacitive Touch Panel',
      Koneksi: 'WiFi, Bluetooth, 4G, GPS, NFC (Optional)',
      'Sumber Daya': 'Input: 100-240V; Output: 24V 2.5A',
      Speaker: '1.5W Mono',
      Pemindaian: '1D / 2D Code',
      Kamera: 'Mendukung Scanning Pay, 0.3M, FF',
      Printer: 'Kecepatan 100mm/s. Mendukung lebar kertas 58mm dan diameter 40mm',
      Berat: '900 g',
      Sertifikasi: 'CE IMDA',
    },
    additionalContent: null,
    showInFooter: false,
    relatedProducts: ['imin-falcon-1', 'sunmi-d2-mini', 'sunmi-t2-mini', 'imin-m2-max'],
  },
  {
    name: 'iMin D1w',
    slug: 'imin-d1w',
    metaTitle:
      'iMin D1w Mesin Kasir Desktop dengan Integrasi Timbangan | Solusi Bersama Informatika',
    metaDescription:
      'iMin D1w, mesin kasir desktop yang terintegrasi dengan timbangan untuk pengelolaan penjualan yang akurat. Dapatkan kecepatan, kehandalan, dan kemudahan penggunaan dengan iMin D1w. Hubungi Solusi Bersama Informatika sekarang!',
    metaKeywords:
      'iMin, iMin D1w, mesin kasir, mesin kasir android, mesin kasir portable, timbangan, mesin kasir timbangan, scale device, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IMIN.name,
    category: PRODUCT_CATEGORIES.DESKTOP_TERMINAL.name,
    image1: 'imin-d1w-01.png',
    image2: 'imin-d1w-02.png',
    image3: 'imin-d1w-03.png',
    productCatalog: 'sbisolution-imin-d1w.pdf',
    description:
      'iMin D1w adalah mesin kasir desktop yang menyediakan integrasi dengan timbangan untuk pengelolaan penjualan yang akurat dan efisien. Dirancang dengan kecepatan, kehandalan, dan kemudahan penggunaan, iMin D1w membantu meningkatkan efisiensi dalam sistem pembayaran dan manajemen stok. iMin D1w memiliki kemampuan untuk terhubung langsung dengan timbangan, memungkinkan Anda memperhitungkan berat produk secara otomatis saat melakukan penjualan, hal ini akan mengurangi kesalahan penghitungan dan meningkatkan akurasi dalam sistem kasir. Dengan iMin D1w, Anda akan mendapatkan solusi mesin kasir desktop yang terintegrasi dengan timbangan, memberikan pengalaman penjualan yang akurat, cepat, dan efisien. Hubungi Solusi Bersama Informatika sekarang untuk informasi lebih lanjut mengenai Imin D1W dan bagaimana mesin kasir ini dapat meningkatkan operasional bisnis Anda.',
    specifications: {
      Layar: 'Main Display: 15.6" (FHD) 1920×1080; Secondary Display: 15.6" (FHD) 1920×1080',
      Memory: 'RAM: 2GB, ROM: 16GB',
      'Ukuran & Dimensi': 'Mesin: 410 x 453 x 493 mm, Timbangan: 410 x 310 x 84.7 mm',
      'Sambungan Periferal':
        'Type-A x 5, Micro USB x 1, RJ45 x 1, RJ11 x 1, RJ12 x 1, TF-Card x 1, DC Jack x 1',
      Processor: 'Quad-Core Cortex-A17, 1.8 GHz',
      'Operating System': 'Android 11 iMin UI',
      'Layar Sentuh': 'Multi-point Capacitive Touch Panel',
      'Sumber Daya': 'Input: 100-240V; Output: 24V 4.2A',
      Koneksi: 'Ethernet: 100M, Wi-Fi: 802.11 b/g/n 2.4GHz, Bluetooth 4.2',
      Speaker: '1.5W Mono',
      Printer: 'Kecepatan 250mm/s. Pemotongan otomatis. Mendukung lebar kertas 58mm dan 80mm',
      'Penyimpanan Tambahan': 'TF Card up to 128GB',
      Berat: '22kg',
      'Akurasi Timbangan': '0.1-15kg: Interval +/- 5g; 0.1-30kg: Interval +/- 10g',
      Sertifikasi: 'CE IMDA',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['sunmi-s2'],
  },
  {
    name: 'iMin SWIFT 1',
    slug: 'imin-swift-1',
    metaTitle: 'iMin SWIFT 1 Mesin Kasir Portable Printer & Scanner | Solusi Bersama Informatika',
    metaDescription:
      'iMin SWIFT 1 mesin kasir portable dengan kinerja andal dan fleksibilitas tinggi. Solusi praktis untuk bisnis Anda di Solusi Bersama Informatika',
    metaKeywords:
      'iMin, iMin SWIFT 1, mesin kasir, mesin kasir android, mesin kasir portable, timbangan, mesin kasir timbangan, scale device, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IMIN.name,
    category: PRODUCT_CATEGORIES.MOBILE_TERMINAL.name,
    image1: 'imin-swift-1-01.png',
    image2: 'imin-swift-1-02.png',
    image3: 'imin-swift-1-03.png',
    productCatalog: 'sbisolution-imin-swift-1.pdf',
    description:
      'iMin SWIFT 1 adalah mesin kasir portable yang memberikan kemudahan dan fleksibilitas tinggi untuk bisnis Anda. Didesain secara khusus untuk memenuhi kebutuhan toko atau usaha yang membutuhkan sistem kasir yang praktis dan mudah digunakan. Dengan iMin SWIFT 1, Anda dapat mengoptimalkan pengalaman bertransaksi dan meningkatkan efisiensi operasional toko Anda. Fleksibilitas dan portabilitasnya membuatnya cocok untuk berbagai jenis bisnis seperti toko retail, kafe, restoran, dan banyak lagi. Dapatkan mesin kasir portable iMin SWIFT 1 dengan kinerja andal dan solusi praktis hanya di Solusi Bersama Informatika. Hubungi kami sekarang untuk informasi lebih lanjut dan penawaran terbaik!',
    specifications: {
      Layar: '6.5 inch, resolusi 720 x 1600 px',
      Memory: '2GB RAM + 16GB ROM; 4GB RAM + 32GB ROM',
      Dimensi: '22.5 x 8.6 x 6.6 cm (printer terpasang)',
      Prosessor: 'Octa-Core, 8 x A55, 1.6GHz',
      'Operating System': 'Android 11 iMin UI',
      Konektivitas: 'Wi-Fi: 802.11 a/b/g/n/ac (2.4GHz/5GHz), Bluetooth 5.0, NFC',
      'Layar Sentuh': 'Multi-point Capacitive Touch Panel',
      Kamera: '0.3 MP front camera, 5 MP rear camera',
      'Sumber Daya': 'Input: 5V 2A',
      'Sambungan Periferal': 'Nano SIM x 1, TF Card x 1, USB Type-C x 1, Pogo pin (6 pin)',
      Speaker: '0.8W Mono',
      Baterai: '7.6V 2550mAh',
      Printer: 'Kecepatan 100mm/s. Mendukung lebar kertas 58mm dan diameter 40mm',
      Scanner: '1D/QR Code',
      Berat: 'PDA: 245g, Swift 1 Printer: 380g, Swift 1Scanner: 290g',
      Sertifikasi: 'FCC, CE, IMDA, WEEE',
    },
    additionalContent: null,
    showInFooter: true,
  },
  {
    name: 'iMin M2 Max',
    slug: 'imin-m2-max',
    metaTitle: 'iMin M2 Max Mesin Kasir Portable | Solusi Bersama Informatika',
    metaDescription:
      'Solusi kasir portable terbaik dengan iMin M2 Max, fleksibilitas dan performa tinggi untuk bisnis Anda. Dapatkan di Solusi Bersama Informatika',
    metaKeywords:
      'iMin, iMin M2 Max, mesin kasir, mesin kasir android, mesin kasir portable, timbangan, mesin kasir timbangan, scale device, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IMIN.name,
    category: PRODUCT_CATEGORIES.MOBILE_TERMINAL.name,
    image1: 'imin-m2-max-01.png',
    image2: 'imin-m2-max-02.png',
    image3: 'imin-m2-max-03.png',
    image4: 'imin-m2-max-04.png',
    image5: 'imin-m2-max-05.png',
    productCatalog: 'sbisolution-imin-m2-max.pdf',
    description:
      'iMin M2 Max adalah mesin kasir portable terbaik yang memberikan fleksibilitas dan performa tinggi untuk bisnis Anda. Dirancang dengan fitur-fitur canggih, mesin kasir ini memastikan pengalaman bertransaksi yang optimal dan efisien. Dengan iMin M2 Max, Anda dapat mengoptimalkan proses transaksi dan meningkatkan produktivitas bisnis Anda. Mesin kasir portable ini cocok untuk berbagai jenis usaha seperti toko retail, restoran, kafe, dan banyak lagi. Dapatkan solusi kasir portable terbaik dengan iMin M2 Max hanya di Solusi Bersama Informatika. Hubungi kami sekarang untuk informasi lebih lanjut dan penawaran terbaik!',
    specifications: {
      Layar: '8" 800×1280 Display',
      Memory: 'RAM: 2GB/4GB, ROM: 16GB/64GB',
      Ukuran: '212.3 x 132 x 76.2 mm',
      'Sambungan Periferal': 'Type-A x 1, Nano SIM x 2, USB Type-C x 1 (Support OTG)',
      Processor: 'Octa-Core 1.8GHz, Dual A75 & Hexa A55',
      'Operating System': 'Android 11 Go iMin UI',
      'Layar Sentuh': 'Multi-point Capacitive Touch Panel',
      Kamera: 'Mendukung scanning pay 0.3MM, FF',
      'Sumber Daya': 'Input: 100-240V AC; Output: 5V DC 2A',
      Baterai: '7.4V / 2600mAh',
      Koneksi: 'Wi-Fi: 2.4 / 5Ghz, Bluetooth: BT4.2, GPS/GSM/4G LTE, NFC (On request)',
      Speaker: '1.5W Mono',
      Berat: '598g',
      Printer: 'Kecepatan 100mm/s. Mendukung lebar kertas 58mm dan diameter 40mm',
      Pemindaian: '1D / 2D Code',
      Sertifikasi: 'CE IMDA',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['sunmi-v2', 'sunmi-v2-pro', 'imin-m2', 'imin-swift-1'],
  },
  {
    name: 'iMin M2',
    slug: 'imin-m2',
    metaTitle: 'iMin M2 Mesin Kasir Portable | Solusi Bersama Informatika',
    metaDescription:
      'Solusi kasir portable praktis dan efisien dengan iMin M2. Dapatkan kemudahan transaksi di mana saja dengan kehandalan dan performa tinggi. Solusi terbaik dari Solusi Bersama Informatika.',
    metaKeywords:
      'iMin, iMin M2, mesin kasir, mesin kasir android, mesin kasir portable, timbangan, mesin kasir timbangan, scale device, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IMIN.name,
    category: PRODUCT_CATEGORIES.MOBILE_TERMINAL.name,
    image1: 'imin-m2-01.png',
    image2: 'imin-m2-02.png',
    image3: 'imin-m2-03.png',
    image4: 'imin-m2-04.png',
    productCatalog: 'sbisolution-imin-m2.pdf',
    description:
      'iMin M2 adalah mesin kasir portable yang memberikan kemudahan transaksi praktis dan efisien untuk bisnis Anda. Didesain dengan fitur-fitur unggulan, mesin kasir ini memberikan kehandalan dan performa tinggi dalam setiap transaksi. Desain ringkas dan portabel, memudahkan Anda untuk membawa dan menggunakannya di berbagai lokasi. Dengan iMin M2, Anda dapat melakukan transaksi dengan mudah dan cepat di mana saja. Cocok untuk berbagai jenis usaha seperti toko retail, restoran, kafe, dan banyak lagi. Dapatkan solusi kasir portable praktis dan efisien dengan iMin M2 hanya di Solusi Bersama Informatika. Hubungi kami sekarang untuk informasi lebih lanjut dan penawaran terbaik!',
    specifications: {
      Layar: '5.5" 720×1280 Display',
      Memory: 'RAM: 1GB/2GB, ROM: 8GB/16GB',
      Ukuran: '207.5 x 82 x 30.5 mm',
      'Sambungan Periferal':
        'USB Type-Cx1 (Supports OTG), 3.5mm Audio Jackx1, TF Cardx1 (up to 64GB), POGO PIN (6PIN), Psam Cardx1 (NFC)',
      Processor: 'Quad-Core ARM Cortex-A53 1.3GHz',
      'Operating System': 'Android 8.1 iMin UI',
      'Layar Sentuh': 'Multi-point Capacitive Touch Panel',
      Kamera: 'Mendukung scanning pay, 5.0MM, AF, GC5025, Flash, Micro-pitch',
      'Sumber Daya': 'Input: 100-240V. Output: 5V 2A',
      Baterai: '7.4V / 2600mAh',
      Koneksi: 'Wi-Fi: 2.4GHz / 5GHz, 802.11 a/b/g/n, Bluetooth 4.2',
      Speaker: '1.5W Mono',
      Berat: '435g',
      Printer: 'Kecepatan 100mm/s. Mendukung lebar kertas 58mm dan diameter 40mm',
      Pemindaian: '1D / 2D Code',
      Sertifikasi: 'CE IMDA',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['sunmi-v2', 'sunmi-v2-pro', 'imin-swift-1', 'imin-m2-max'],
  },
  {
    name: 'iData K1S',
    slug: 'idata-k1s',
    metaTitle: 'iData K1S PDT Barcode Scanner | Solusi Bersama Informatika',
    metaDescription:
      'Solusi Barcode Scanner berkualitas dengan iData K1S. Peningkatan efisiensi bisnis Anda dengan teknologi canggih. Dapatkan produk terbaik hanya di Solusi Bersama Informatika',
    metaKeywords:
      'iData, iData K1S, pdt, pdt android, barcode, scanner, barcode scanner, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IDATA.name,
    category: PRODUCT_CATEGORIES.PDT.name,
    image1: 'idata-k1s-01.png',
    image2: 'idata-k1s-02.png',
    image3: 'idata-k1s-03.png',
    image4: 'idata-k1s-04.png',
    productCatalog: 'sbisolution-idata-k1s.pdf',
    description:
      'iData K1S adalah solusi barcode scanner terdepan untuk meningkatkan efisiensi bisnis Anda. Dengan teknologi canggih dan desain ergonomis, scanner ini memastikan kecepatan dan akurasi dalam membaca barcode. Nyaman digunakan dalam jangka waktu lama dan dapat membaca berbagai jenis barcode, termasuk 1D dan 2D. iData K1S merupakan pilihan sempurna untuk berbagai industri, seperti retail, logistik, manufaktur, dan lainnya. Dengan kecepatan dan akurasi pemindaian, Anda dapat meningkatkan produktivitas dan efisiensi bisnis Anda. Dapatkan solusi barcode scanner berkualitas dengan iData K1S hanya di Solusi Bersama Informatika. Hubungi kami sekarang untuk informasi lebih lanjut dan penawaran terbaik!',
    specifications: {
      Processor: 'Octa-core 2.0GHz high-performance processor',
      'Operating System': 'Android 9.0 (GMS) / Android 11.0 (GMS)',
      Memory: '64GB ROM + 4GB RAM (standard)',
      SIM: 'Single SIM card\nWi-Fi Only: SIM not available',
      'Expansion Slot': 'Micro SD card (up to 256GB)',
      'Display Screen': '4-inch, resolution: 800*480 pixels',
      'Touch Screen':
        'Industrial capacitive screen, support wet-hand operation/glove mode/multi-touch/gesture operation',
      Camera: '13 megapixels of rear camera, support auto-focusing/PDAF',
      Flashlight: '800mA flashlight',
      Keypad: '26 keypads: Front Key x 23, Side Scan Key x 2, Side Power Key x 1',
      'Keypad Backlight': 'White',
      Battery:
        '3.8V lithium battery, with 4400mAh, detachable, backup-battery support (support on selected terminals)',
      Audio: 'Built-in microphone',
      'Charging Method': 'Type-C charging and pin port charging',
      Reminder: 'Vibration reminder/LED reminder',
      'Vibrating Motor': 'Built-in vibrating motor',
      Sensors: 'G-sensor/ Proximity sensor/Light sensor',
      'Intercom Function (Optional)': 'Support one-button PTT calls',
      Dimension: '168mm x 71mm x 26mm',
      Weight: '270g (including the battery)',
      'WWAN (Data Service)':
        'GSM/GPRS/EDGE/CDMA/UMTS/HSPA/HSPA+/EVDO/WCDMA/TD-SCDMA/TDD-LTE/FDD-LTE\nWi-Fi Only Version: Not available',
      'WLAN Function':
        'Wi-Fi 802.11a/b/g/n/r/ac/i/k/v (2.4G + 5G dual-band Wi-Fi), support Wi-Fi 5G PA amplifier',
      Bluetooth: 'Bluetooth 5.0, support BLE',
      GPS: 'GPS',
      'Working Temperature': '-20℃ - 60℃',
      'Storage Temperature': '-40℃ - 70℃ (without the battery)',
      Humidity: '0 - 95% (no moisture condensation)',
      'Drop Specification': 'Multiple drops to concrete floor from the height of 1.5m',
      'Roller Drop Specification': '1000 times of roller drops from a height of 0.5 meters',
      'Ingress Protection': 'IP65',
      'ESD Protection': '±15KV (air discharge), ±8KV (contact discharge)',
      'Barcode Type': 'It can scan one-dimensional / two-dimensional barcodes',
      'Scanning Accuracy': '≥3.33mil',
      'Scanning Angle': 'Pitch: ±60°; Skew: ±45°; Tilt: 360°',
      'View Angle': 'Horizontal: 44.3°, Vertical: 28.4°, Diagonal: 51°',
      'Motion Error Tolerance': '8m/s',
      'NFC Frequency': '13.56MHz',
      'NFC Reading Distance': 'Within 30mm',
      'NFC Communication Protocol': 'ISO14443A/14443B/15693',
      'USB Interface': 'Type-C (with headset function) x 1',
      'OTG Interface': 'Support',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['idata-70', 'idata-i3', 'kaicom-w660', 'imin-swift-1'],
  },
  {
    name: 'iData 70',
    slug: 'idata-70',
    metaTitle: 'iData 70 PDT Barcode Scanner | Solusi Bersama Informatika',
    metaDescription:
      'Solusi Barcode Scanner berkualitas dengan iData 70. Peningkatan efisiensi bisnis Anda dengan teknologi canggih. Dapatkan produk terbaik hanya di Solusi Bersama Informatika',
    metaKeywords:
      'iData, iData 70, pdt, pdt android, barcode, scanner, barcode scanner, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IDATA.name,
    category: PRODUCT_CATEGORIES.PDT.name,
    image1: 'idata-70-01.png',
    image2: 'idata-70-02.png',
    image3: 'idata-70-03.png',
    productCatalog: 'sbisolution-idata-70.pdf',
    description:
      'iData 70 adalah solusi barcode scanner terdepan untuk meningkatkan efisiensi bisnis Anda. Dengan teknologi canggih dan desain ergonomis, scanner ini memastikan kecepatan dan akurasi dalam membaca barcode. Nyaman digunakan dalam jangka waktu lama dan dapat membaca berbagai jenis barcode, termasuk 1D dan 2D. iData 70 merupakan pilihan sempurna untuk berbagai industri, seperti retail, logistik, manufaktur, dan lainnya. Dengan kecepatan dan akurasi pemindaian, Anda dapat meningkatkan produktivitas dan efisiensi bisnis Anda. Dapatkan solusi barcode scanner berkualitas dengan iData 70 hanya di Solusi Bersama Informatika. Hubungi kami sekarang untuk informasi lebih lanjut dan penawaran terbaik!',
    specifications: {
      Processor: 'Basic models: Quad-core 1.3 GHz\nUpgrade models: Octa-core 2.0 GHz',
      'Operating System': 'Basic models: Android 6.0\nUpgrade models: Android 10.0',
      Memory: 'Basic models: 16GB+2GB\nUpgrade models: 16GB+2GB, 64GB+4GB(optional)',
      'SIM	Basic models': 'Single SIM card',
      'Expansion Slot':
        'Basic models: Micro SD (Up to 32GB)\nUpgrade models: Micro SD card, up to 256 GB',
      'Display screen': '4-inch, resolution: 800*480 pixel',
      'Touch screen':
        'Industrial capacitive screen, supporting two-point touch + gesture operation',
      Camera:
        'Basic models: 8 MP autofocus camera in the back side, LED flash\nUpgrade models: 8 MP autofocus camera in the back side (13 MP optional), LED flash',
      Flashlight: '800mA flashlight',
      Keyboard:
        'Front Key x 20, Side Scan Key x 2, Side Custom Key x 2 (internal light-transmitting industrial IMD keyboard)',
      Battery: 'Lithium battery 3.8V, 4000mAh, removable',
      Audio: 'Built-in microphone',
      Charging:
        'Basic models: Type-C charging and pin port charging\nUpgrade models: Type-C charging and pin port charging, 18W fast charging',
      Prompts: 'High-power speaker/vibration reminder/LED reminder/audio reminder',
      'Vibrating motor': 'Built-in vibrating motor',
      Sensor: 'Gravity sensor (G-sensor) / proximity sensor / light sensor / geomagnetic sensor',
      'Intercom function (optional)': 'Supporting one-button PTT calls',
      Dimension: '159mm x 71mm x 20mm',
      Weight: '262g',
      'WWAN (Data service)':
        'GSM/GPRS/EDGE/CDMA/UMTS/HSPA/HSPA+/EVDO/WCDMA/TD-SCDMA/TDD-LTE/FDD-LTE',
      WLAN: 'Basic models: Wi-Fi 802.11a/b/g/n (2.4G+5G dual-band Wi-Fi)\nUpgrade models: Wi-Fi 802.11a/b/g/n/r/ac (2.4G+5G dual-band Wi-Fi), Wi-Fi 5G PA',
      Bluetooth: 'Basic models: Bluetooth 4.0\nUpgrade models: Bluetooth 5.0',
      GPS: 'Basic models: GPS/AGPS/Beidou/GLONASS\nUpgrade models: GPS/AGPS/Beidou/Galileo/GLONASS/QZSS',
      'Working temperature': '-20℃~60℃',
      'Storage temperature': '-40℃~60℃ (Without battery)',
      Humidity: '0%~95% (no condensation)',
      'Drop specification': '1.5m concrete floor dropped several times',
      'Protection Level': 'IP65',
      'Electrostatic Protection': '±15KV (air discharge), ±8KV (contact discharge)',
      Barcode: 'Support 1D/2D scanning engine',
      'Scanning Accuracy': '≥3.33mil',
      'Scanning Angle': 'Tilt ±60°, deflection ±60°, rotation 360°',
      'Angular Field of View': 'Horizontal: 44.3°; Vertical: 28.4°; Diagonal: 51°',
      'Motion Tolerance': '8m/s',
      'NFC Frequency': '13.56MHz',
      'NFC Reading Distance': 'Within 10mm',
      'NFC Communication Protocol': 'ISO14443A/14443B/15693',
      'USB interface': 'Type-C (with earphone function) x 1',
      OTG: 'Supporting',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['idata-k1s', 'idata-i3', 'kaicom-w660', 'imin-swift-1'],
  },
  {
    name: 'iData i3',
    slug: 'idata-i3',
    metaTitle: 'iData i3 PDT Barcode Scanner | Solusi Bersama Informatika',
    metaDescription:
      'Solusi Barcode Scanner berkualitas dengan iData i3. Peningkatan efisiensi bisnis Anda dengan teknologi canggih. Dapatkan produk terbaik hanya di Solusi Bersama Informatika',
    metaKeywords:
      'iData, iData i3, pdt, pdt android, barcode, scanner, barcode scanner, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IDATA.name,
    category: PRODUCT_CATEGORIES.PDT.name,
    image1: 'idata-i3-01.png',
    image2: 'idata-i3-02.png',
    image3: 'idata-i3-03.png',
    productCatalog: 'sbisolution-idata-i3.pdf',
    description:
      'iData i3 adalah solusi barcode scanner terdepan untuk meningkatkan efisiensi bisnis Anda. Dengan teknologi canggih dan desain ergonomis, scanner ini memastikan kecepatan dan akurasi dalam membaca barcode. Nyaman digunakan dalam jangka waktu lama dan dapat membaca berbagai jenis barcode, termasuk 1D dan 2D. iData i3 merupakan pilihan sempurna untuk berbagai industri, seperti retail, logistik, manufaktur, dan lainnya. Dengan kecepatan dan akurasi pemindaian, Anda dapat meningkatkan produktivitas dan efisiensi bisnis Anda. Dapatkan solusi barcode scanner berkualitas dengan iData i3 hanya di Solusi Bersama Informatika. Hubungi kami sekarang untuk informasi lebih lanjut dan penawaran terbaik!',
    specifications: {
      Processor: 'Quad-core 2.0GHz high-performance processor',
      'Operating System': 'Android 10.0/Android 11.0',
      Memory: '16GB + 2GB (standard)\n32GB + 3GB/64GB + 4GB (optional)',
      SIM: 'Single SIM card',
      'Display Screen': '3.2-inch, resolution: 800*480 pixels',
      'Touch Screen':
        'Industrial capacitive screen, support wet-hand operation/glove mode/multi-touch/gesture operation',
      Camera: '8 megapixels rear camera(optional 13 megapixels), support auto-focus',
      Flashlight: '800mA flashlight',
      Keypad: '24 keypads in total: Front Key x 21, Side Scan Key x 2, Side Power Key x 1',
      Battery: '3.85V lithium battery, with 3000mAh, undetachable',
      Audio: 'Built-in microphone',
      'Charging Method': 'Type-C charging, support 18W fast charging',
      Reminder: 'High power speaker reminder/vibration reminder/LED reminder/Audio reminder',
      'Vibrating Motor': 'Built-in vibrating motor',
      Sensor: 'G-sensor',
      'Intercom Function (Optional)': 'Support one-button PTT calls',
      Dimension: '158mm x 54mm x 18.5mm',
      Weight: '178g (including the battery)',
      'WWAN (Data Service)': 'GSM/GPRS/EDGE/UMTS/HSPA/HSPA+WCDMA/TD-SCDMA/TDD-LTE/FDD-LTE',
      'WLAN Function':
        'Wi-Fi 802.11a/b/g/n/r/ac (2.4G + 5G dual-band Wi-Fi), support Wi-Fi 5G PA amplifier, fast roaming',
      Bluetooth: 'Bluetooth 5.0, support BLE',
      GPS: 'GPS/AGPS/BDS/Galileo/GLONASS/QZSS',
      'Storage Temperature': '-40℃ - 70℃ (without the battery)',
      Humidity: '0 - 95% (no moisture condensation)',
      'Drop Specification': 'Multiple drops to concrete floor from the height of 1.5m',
      'Roller Drop Specification': '500 times of roller drops from a height of 0.5 meters',
      'Ingress Protection': 'IP54',
      'ESD Protection': '±15KV (air discharge), ±8KV (contact discharge)',
      'Barcode Type': 'Can scan 1D / 2D barcodes',
      'Scanning Accuracy': '≥3.33mil',
      'Scanning Angle': 'Pitch: ±60°; Skew: ±45°; Tilt: 360°',
      'View Angle': 'Horizontal: 44.3°; Vertical: 28.4°; Diagonal: 51°',
      'Motion Error Tolerance': '8m/s',
      'NFC Reading Distance': 'Within 60mm',
      'NFC Communication Protocol': 'ISO14443A/14443B/15693',
      'USB Interface': 'Type-C (with headset function) x 1',
      'OTG Interface': 'Support',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['idata-k1s', 'idata-70', 'kaicom-w660', 'imin-swift-1'],
  },
  {
    name: 'iData T2 UHF',
    slug: 'idata-t2-uhf',
    metaTitle: 'iData T2 UHF - RFID Scanner | Solusi Bersama Informatika',
    metaDescription:
      'iData T2 UHF, RFID scanner yang handal dan efisien untuk manajemen inventaris dan pelacakan barang di gudang Anda. Dapatkan teknologi terkini untuk kebutuhan bisnis Anda hanya di Solusi Bersama Informatika',
    metaKeywords:
      'iData, iData T2, rfid, rfid scanner, pdt, pdt android, barcode, scanner, barcode scanner, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IDATA.name,
    category: PRODUCT_CATEGORIES.RFID_TERMINAL.name,
    image1: 'idata-t2-uhf-01.png',
    image2: 'idata-t2-uhf-02.png',
    productCatalog: 'sbisolution-idata-t2-uhf.pdf',
    description:
      'iData T2 UHF adalah solusi terbaik untuk kebutuhan manajemen inventaris dan pelacakan barang dalam bisnis Anda. Dengan teknologi RFID (Radio Frequency Identification) yang canggih, perangkat ini mampu membaca dan mengumpulkan data dari tag RFID dengan cepat dan akurat. iData T2 UHF merupakan solusi terdepan dalam mengoptimalkan manajemen inventaris dan pelacakan barang di berbagai industri. Perangkat ini membantu Anda memantau stok barang dengan akurat, meningkatkan efisiensi proses pengiriman, dan mengurangi waktu yang dibutuhkan dalam proses manajemen. Jadikan iData T2 UHF sebagai pilihan tepat untuk meningkatkan produktivitas dan efisiensi bisnis Anda. Segera dapatkan perangkat ini dan manfaatkan teknologi RFID terdepan dari Solusi Bersama Informatika.',
    specifications: {
      Processor: 'Octa-core 2.0GHz high-performance processor',
      'Operating System': 'Android 12.0 (GMS)',
      Memory: '64GB+4GB (standard)\n128GB+6GB(optional)',
      SIM: 'Two SIM card slots',
      'Expansion Slot': 'Micro SD card (up to 256 GB)',
      'Display Screen': '6.21-inch, resolution: 1520*720 pixels',
      'Touch Screen':
        'Industrial capacitive screen, support wet-hand operation/glove mode/multi-touch/gesture operation',
      Camera: '16MP top camera; 5MP front camera; support auto-focus',
      Flashlight: '800mA flashlight',
      Keypad: '6 keypads in total: scan key x 2, power key x 1, volume key x 2, pistol trigger x 1',
      Battery:
        '3.85V lithium battery, with 4500mAh, undetachable\n3.7V lithium battery, with 6700mAh, detachable',
      Audio: 'Built-in microphone',
      'Charging Method': 'Type-C and Pin-port charging, support 18W fast charging',
      Reminder: 'Vibration reminder/LED reminder/high power speaker/Audio reminder',
      'Vibrating Motor': 'Built-in vibrating motor',
      Sensor: 'G-sensor/Proximity sensor/Light sensor/Geomagnetic sensor/ Gyroscope',
      'Intercom Function (Optional)': 'Support one-button PTT calls',
      'Cooling fan (Optional)': 'Built-in cooling fan',
      Dimension: '168mm x 97mm x 14.5mm',
      Weight: '680g (including the battery)',
      'WWAN (Data Service)':
        'GSM/GPRS/EDGE/CDMA/UMTS /HSPA/HSPA+/EVDO/WCDMA/ TD-SCDMA/TDD-LTE/FDD-LTE',
      'WLAN Function': 'Wi-Fi 802.11/a/b/g/n/ ac/d/e/h/i/j/ k/r/v/w (2.4G + 5G dual-band Wi-Fi)',
      Bluetooth: 'Bluetooth 5.0',
      GPS: 'GPS',
      'Working Temperature': '-20℃ - 60℃',
      'Storage Temperature': '-40℃ - 70℃ (Without battery)',
      Humidity: '0 - 95% (no moisture condensation)',
      'Drop Specification': 'Multiple drops to concrete floor from the height of 1.5m',
      'Roller Drop Specification': '1000 times of roller drops from a height of 0.5 meter',
      'Ingress Protection': 'IP67',
      'ESD Protection': '±15KV (air discharge), ±8KV (contact discharge)',
      'Barcode Type': 'Can scan 1D / 2D barcodes',
      'Scanning Accuracy': '≧3.33mil',
      'Scanning Angle': 'Pitch: ±60°; Skew: ±45°; Tilt: 360°',
      'View Angle': 'Horizontal: 44.3°, Vertical: 28.4°, Diagonal: 51°',
      'Motion Error Tolerance': '8m/s',
      'NFC Frequency (Optional)': '13.56 MHz',
      'NFC Reading Distance (Optional)': 'Within 60 mm',
      'NFC Communication Protocol': 'ISO14443A/14443B/15693',
      'RFID Communication Protocol': 'ISO18000-6C/EPC Class1 Gen2',
      'RFID Frequency': '920MHz~925MHz/902MHz~928MHz /865MHz~868MHz',
      'RFID Output Power': '8~33dBm',
      'RFID Antenna': 'Circular polarization antenna',
      'RFID Reading Distance': 'Up to 20m',
      'RFID Reading Rate': 'Up to 700tags/s',
      'USB Interface': 'Type-C (with headset function) x 1',
      'OTG Interface': 'Support',
      'PSAM (Optional)': 'Support PSAM x 1',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['idata-50-uhf'],
  },
  {
    name: 'iData 50 UHF',
    slug: 'idata-50-uhf',
    metaTitle: 'iData 50 UHF - RFID Scanner | Solusi Bersama Informatika',
    metaDescription:
      'iData 50 UHF, RFID scanner yang handal dan efisien untuk manajemen inventaris dan pelacakan barang di gudang Anda. Dapatkan teknologi terkini untuk kebutuhan bisnis Anda hanya di Solusi Bersama Informatika',
    metaKeywords:
      'iData, iData 50 UHF, rfid, rfid scanner, pdt, pdt android, barcode, scanner, barcode scanner, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IDATA.name,
    category: PRODUCT_CATEGORIES.RFID_TERMINAL.name,
    image1: 'idata-50-uhf-01.png',
    image2: 'idata-50-uhf-02.png',
    image3: 'idata-50-uhf-03.png',
    image4: 'idata-50-uhf-04.png',
    image5: 'idata-50-uhf-05.png',
    productCatalog: 'sbisolution-idata-50-uhf.pdf',
    description:
      'iData 50 UHF adalah solusi terbaik untuk kebutuhan manajemen inventaris dan pelacakan barang dalam bisnis Anda. Dengan teknologi RFID (Radio Frequency Identification) yang canggih, perangkat ini mampu membaca dan mengumpulkan data dari tag RFID dengan cepat dan akurat. iData 50 UHF merupakan solusi terdepan dalam mengoptimalkan manajemen inventaris dan pelacakan barang di berbagai industri. Perangkat ini membantu Anda memantau stok barang dengan akurat, meningkatkan efisiensi proses pengiriman, dan mengurangi waktu yang dibutuhkan dalam proses manajemen. Jadikan iData 50 UHF sebagai pilihan tepat untuk meningkatkan produktivitas dan efisiensi bisnis Anda. Segera dapatkan perangkat ini dan manfaatkan teknologi RFID terdepan dari Solusi Bersama Informatika.',
    specifications: {
      Processor: 'Octa-core 2.0GHz high-performance processor',
      'Operating System': 'Android 9.0',
      Memory: '16GB+2GB (standard)\n64GB+4GB(optional)',
      SIM: 'Single SIM card',
      'Expansion Slot': 'Micro SD card (up to 256 GB)',
      'Display Screen': '4.7-inch, resolution: 1280x720 pixels',
      'Touch Screen':
        'Industrial capacitive screen, support wet-hand operation/glove mode/multi-touch/gesture operation',
      Camera:
        '8MP rear camera (optional 13 MP), 5MP front camera, support auto-focus (LED fill in light)',
      Flashlight: '800mA flashlight',
      Keyboard:
        '9 keypads in total: front key x 4, side scan key x 2, power key x 1, side custom key x 2',
      Battery:
        'Main unit : 3.85V lithium battery, with 4000mAh, detachable\nPistol grip: 3.6V lithium battery, with 5200mAh, undetachable',
      Audio: 'Built-in microphone',
      'Charging Method': 'Type-C and Pin-port charging, support 24W fast charging',
      Reminder: 'Vibration reminder/LED reminder/Audio reminder',
      'Vibrating Motor': 'Built-in vibrating motor',
      Sensor: 'G-sensor/Proximity sensor/Light sensor',
      'Intercom Function (Optional)': 'Support one-button PTT calls',
      Dimension: '175mm x 89mm x 165mm',
      Weight: '710g (including the battery and RFID)',
      'WWAN (Data Service)':
        'GSM/GPRS/EDGE/CDMA/UMTS/ HSPA/HSPA+/EVDO/WCDMA/ TD-SCDMA/TDD-LTE/FDD-LTE',
      'WLAN Function':
        'Wi-Fi 802.11ac/a/b/g/n/r (2.4G + 5G dual-band Wi-Fi) support Wi-Fi 5G PA amplifier',
      Bluetooth: 'Bluetooth 5.0',
      GPS: 'GPS/AGPS/BDS/Galileo/GLONASS',
      'Working Temperature': '-20℃ - 60℃',
      'Storage Temperature': '-40℃ - 70℃ (Without battery)',
      Humidity: '0 - 95% (no moisture condensation)',
      'Drop Specification': 'Multiple drops to concrete floor from the height of 1.5m',
      'Ingress Protection': 'IP65',
      'ESD Protection': '±15KV (air discharge), ±8KV (contact discharge)',
      'Barcode Type': 'Can scan 1D / 2D barcodes',
      'Scanning Accuracy': '≧3.33mil',
      'Scanning Angle': 'Pitch: ±60°; Skew: ±45°; Tilt: 360°',
      'View Angle': 'Horizontal: 44.3°, Vertical: 28.4°, Diagonal: 51°',
      'Motion Error Tolerance': '8m/s',
      'NFC Frequency (Optional)': '13.56 MHz',
      'NFC Reading Distance (Optional)': 'Within 30 mm',
      'NFC Communication Protocol': 'ISO14443A/14443B/15693',
      'RFID Communication Protocol': 'ISO18000-6B/6C/6D',
      'RFID Frequency': '920MHz~925MHz/ 902MHz~928MHz/ 865MHz~868MHz',
      'RFID Output Power': '5~30dBm',
      'RFID Antenna': 'Circular polarization antenna',
      'RFID Reading Distance': 'Up to 10m',
      'RFID Reading Rate': 'Up to 700tags/s',
      'USB Interface': 'Type-C (with headset function) x 1',
      'OTG Interface': 'Support',
      'PIN Interface': 'Pin port (on the back of main unit) x 1',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['idata-t2-uhf'],
  },
  {
    name: 'iData J16',
    slug: 'idata-j16',
    metaTitle: 'iData J16 Barcode Scanner | Solusi Bersama Informatika',
    metaDescription:
      'Dapatkan scanner barcode iData J16 yang berkualitas dan handal hanya di Solusi Bersama Informatika. Tingkatkan efisiensi bisnis Anda dengan scanner barcode terbaru ini.',
    metaKeywords:
      'iData, iData J16, pdt, pdt android, barcode, scanner, barcode scanner, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IDATA.name,
    category: PRODUCT_CATEGORIES.BARCODE_SCANNER.name,
    image1: 'idata-j16-01.png',
    image2: 'idata-j16-02.png',
    productCatalog: 'sbisolution-idata-j16.pdf',
    description:
      'iData J16 adalah scanner barcode canggih yang akan membawa efisiensi operasional bisnis Anda ke level berikutnya. Scanner barcode ini hadir dengan desain ergonomis dan teknologi mutakhir untuk memastikan pemindaian barcode yang cepat dan akurat, juga tersedia dalam mode koneksi kabel (wired) atau nirkabel (wireless) sesuai kebutuhan. Dengan iData J16, Anda dapat dengan mudah melacak inventaris, mengelola transaksi penjualan, dan meningkatkan efisiensi proses bisnis secara keseluruhan. Scanner ini menjadi mitra ideal untuk toko ritel, gudang, dan berbagai jenis usaha. Jadikan iData J16 sebagai solusi yang tepat untuk meningkatkan produktivitas bisnis Anda. Segera miliki scanner barcode ini hanya di Solusi Bersama Informatika',
    specifications: {
      'Light Sensor': '1280x1080 pixels',
      'Light Source': 'WHITE, RED, BLUE',
      'Scanning Accuracy': '≥3.33mil',
      'Scanning Angle': 'Pitch: ±70°; Skew: ±60°; Tilt: 360°',
      'View Angle': 'Horizontal: 44.3°, Vertical: 28.4°, Diagonal: 51°',
      'Minimum Reading Contract': '15%',
      'Motion Tolerance': '13mil UPC 2m/S (Support up to 8m/s in high motion tolerance mode)',
      Size: '174.82mm(H) x 85.3mm(W) x 64.48mm(D)',
      Weight: '153g',
      'Working Voltage': '4.5V~5.5V (DC)',
      Current: '175mA (typical), 460mA (maximum)',
      Interface: 'USB-HID, USB-CDC, RS232',
      Reminder: 'Buzz reminder/LED reminder',
      'Housing Material': 'PC+ABS',
      'Working Temperature': '0℃~50℃',
      'Storage Temperature': '-20℃~70℃',
      Humidity: '5%~95% (No condensation)',
      'Ingress Protection': 'IP52',
      'Drop Specification': 'Multiple drops to concrete floor from the height of 1.8m',
      'Ambient Light': '0Lux~100000Lux',
      'ESD Protection': '±15KV (air discharge), ±8KV (contact discharge)',
      'Code System':
        '1D : Codabar, Code 11, Code 128, Code 32, Code 39, Code 93, EAN 13, EAN 8, UPC-A, UPC-E, IATA 2 of 5,  Interleaved 2 of 5, Matrix 2 of 5, Straight 2 of 5,  MSI/PIessey, GS1 DataBar, etc.\n2D : Aztec, Data Matrix, MicroPDF 417, PDF 417, QR, Micro QR, Grid Matrix, etc.',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['idata-j15'],
  },
  {
    name: 'iData J15',
    slug: 'idata-j15',
    metaTitle: 'iData J15 Barcode Scanner | Solusi Bersama Informatika',
    metaDescription:
      'Dapatkan scanner barcode iData J15 yang berkualitas dan handal hanya di Solusi Bersama Informatika. Tingkatkan efisiensi bisnis Anda dengan scanner barcode terbaru ini.',
    metaKeywords:
      'iData, iData J15, pdt, pdt android, barcode, scanner, barcode scanner, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.IDATA.name,
    category: PRODUCT_CATEGORIES.BARCODE_SCANNER.name,
    image1: 'idata-j15-01.png',
    image2: 'idata-j15-02.png',
    image3: 'idata-j15-03.png',
    image4: 'idata-j15-04.png',
    image5: 'idata-j15-05.png',
    productCatalog: 'sbisolution-idata-j15.pdf',
    description:
      'iData J15 adalah scanner barcode canggih yang akan membawa efisiensi operasional bisnis Anda ke level berikutnya. Scanner barcode ini hadir dengan desain ergonomis dan teknologi mutakhir untuk memastikan pemindaian barcode yang cepat dan akurat. Dengan iData J15, Anda dapat dengan mudah melacak inventaris, mengelola transaksi penjualan, dan meningkatkan efisiensi proses bisnis secara keseluruhan. Scanner ini menjadi mitra ideal untuk toko ritel, gudang, dan berbagai jenis usaha. Jadikan iData J15 sebagai solusi yang tepat untuk meningkatkan produktivitas bisnis Anda. Segera miliki scanner barcode ini hanya di Solusi Bersama Informatika',
    specifications: {
      'Light Sensor': '1280x1080 pixels',
      'Light Source': 'WHITE',
      'Scanning Accuracy': '≥3.33mil',
      'Scanning Angle': 'Pitch: ±60°; Skew: ±45°; Tilt: 360°',
      'View Angle': 'Horizontal: 39.6°, Vertical: 33.8°, Diagonal: 50.4°',
      'Minimum Reading Contract': '15%',
      'Motion Tolerance': '15mil code128 1.5m/S',
      Size: '175.5mm(H) x 85.3mm(W) x 64.5mm(D)',
      Weight: '158.5g',
      'Working Voltage': '4.5V~5.5V (DC)',
      Current: '160mA (typical), 400mA (maximum)',
      Interface: 'USB-HID, USB-CDC, RS232',
      Reminder: 'Buzz reminder/LED reminder',
      'Housing Material': 'PC+ABS',
      'Working Temperature': '0℃~50℃',
      'Storage Temperature': '-40℃~70℃',
      Humidity: '5%~95% (No condensation)',
      'Ingress Protection': 'IP52',
      'Drop Specification': 'Multiple drops to concrete floor from the height of 1.5m',
      'Ambient Light': '0Lux~100000Lux',
      'ESD Protection': '±15KV (air discharge), ±8KV (contact discharge)',
      'Code System':
        '1D : code128, EAN-8, EAN-13, UPC-A, UPC-E, codabar,  code11, code32, code39, code93, Interleaved 2of 5,  Matrix 2 of 5, IATA 2 of 5, UK Plessey, MSIPlessey, GS1DataBar, etc.\n2D : Aztec, Data Matrix, Micro PDF 417, PDF 417, QR, Micro QR, Han xin code, Grid Matrix, etc.',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['idata-j16'],
  },
  {
    name: 'KAICOM W660',
    slug: 'kaicom-w660',
    metaTitle: 'KAICOM W660 PDT Barcode Scanner | Solusi Bersama Informatika',
    metaDescription:
      'Solusi Barcode Scanner berkualitas dengan KAICOM W660, tingkatkan efisiensi bisnis Anda dengan teknologi canggih. Dapatkan produk terbaik hanya di Solusi Bersama Informatika',
    metaKeywords:
      'kaicom, kaicom w660, pdt, pdt android, barcode, scanner, barcode scanner, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.KAICOM.name,
    category: PRODUCT_CATEGORIES.PDT.name,
    image1: 'kaicom-w660-01.png',
    image2: 'kaicom-w660-02.png',
    image3: 'kaicom-w660-03.png',
    productCatalog: 'sbisolution-kaicom-w660.pdf',
    description:
      'KAICOM W660 adalah solusi barcode scanner terdepan untuk meningkatkan efisiensi bisnis Anda. Dengan teknologi canggih dan desain ergonomis, scanner ini memastikan kecepatan dan akurasi dalam membaca barcode. Nyaman digunakan dalam jangka waktu lama dan dapat membaca berbagai jenis barcode, termasuk 1D dan 2D. KAICOM W660 merupakan pilihan sempurna untuk berbagai industri, seperti retail, logistik, manufaktur, dan lainnya. Dengan kecepatan dan akurasi pemindaian, Anda dapat meningkatkan produktivitas dan efisiensi bisnis Anda. Dapatkan solusi barcode scanner berkualitas dengan KAICOM W660 hanya di Solusi Bersama Informatika. Hubungi kami sekarang untuk informasi lebih lanjut dan penawaran terbaik!',
    specifications: {
      Processor: 'MTK6762 quad core A53 2.0GHIz, quad core A53 1.5GHZ',
      Memory: '2GB+16GB / 3GB+32GB / 4G+64GB',
      'Extensive SD Card': '256GB',
      Display: '4.0 inch IPS, 640x1136; Capacitive screen',
      Camera: '5MP Front Camera, 13MP Rear Camera',
      OS: 'Android 10.0',
      GPS: 'Support GPS, GLONASS, and BEIDOU',
      'External Intertace': 'TYPE-C',
      WAN: '4G: FDD-LTE(B1/B3/B7/B8/B20) TDD-LTE(B38/B40)\n3G: WCDMA(B1/B8)\n2G: GSM/GPRS/EDGE(900/1800)',
      WLAN: '802.11a/b/g/n/ac/d/e/f/hi/k/r (2.4G/5G)',
      Bluetooth: 'BT 5.0',
      NFC: 'ISO144A3A/B, ISO15693',
      'SIM Card Slot': '2 x nano SIM + TF card',
      Battery: '5000mAH + 50mAH',
      'Standby Time': '>100 hours',
      Power: 'Input: 110-240V AC, 50/60Hz; Output: 5.0V DC',
      'Safe Certificate': 'CTA, CCC, CE, GMS',
      'Protection Certificate': 'IP67, 4.9feet (1.5m) drop test',
      'Operation Temperature': '-10°C ~ 55°C',
      'Storage Condition': '-20°C ~ 55°C',
      'Operation Humidity': '20% ~ 85% RH',
      Accessories: 'Standard Hand Strap',
      Dimension: '164mm x 68.5mm x 15.8mm',
      Weight: '270g',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['idata-k1s', 'idata-70', 'idata-i3', 'imin-swift-1'],
  },
  {
    name: 'SMARTECH R330',
    slug: 'smartech-r330',
    metaTitle: 'SMARTECH R330 Mesin Terminal Kasir | Solusi Bersama Informatika',
    metaDescription:
      'Mesin kasir SMARTECH R330, solusi terbaik untuk mencetak struk pembayaran dengan cepat dan efisien. Dapatkan produk berkualitas hanya di Solusi Bersama Informatika',
    metaKeywords:
      'smartech, smartech r330, mesin kasir, mesin kasir android, mesin kasir portable, timbangan, mesin kasir timbangan, scale device, printer, printer struk, printer kasir, solusi pembayaran, sbi, solusi bersama informatika',
    brand: PRODUCT_BRANDS.SMARTECH.name,
    category: PRODUCT_CATEGORIES.MOBILE_TERMINAL.name,
    image1: 'smartech-r330-01.png',
    image2: 'smartech-r330-02.png',
    image3: 'smartech-r330-03.png',
    productCatalog: 'sbisolution-smartech-r330.pdf',
    description:
      'Senraise R330 adalah mesin kasir terbaik yang akan mempermudah operasional bisnis Anda dalam mencetak struk pembayaran dengan kecepatan dan kualitas tinggi. Dengan desain yang ringkas dan fitur canggih, mesin kasir ini merupakan solusi tepat untuk toko, restoran, dan bisnis retail lainnya. Senraise R330 memiliki performa yang handal dan tahan lama, menjadikannya pilihan ideal untuk bisnis dengan volume transaksi tinggi. Hubungi kami sekarang untuk informasi lebih lanjut dan penawaran khusus!',
    specifications: {
      Processor: 'Qualcomm Snapdragon 425 Cortex-A53 Quad Core 1.4GHz',
      OS: 'Android 7.1 Senraise OS',
      Memory: '2GB RAM + 16GB ROM',
      Display: '5.2"HD 1280x720 IPS, Capacitive Multitouch Screen',
      'Wi-Fi': 'Support IEEE 802.11\nb/g/n 2.4GHz\n5GHz (4G version supports)',
      Bluetooth: 'Support BLE\nBluetooth 2.1/3.0/4.0',
      Printer: 'Built-in high speed 58mm x 40mm 70mm/s',
      Battery: '7.2V, 2600mAh',
      Camera: 'Rear camera 5MP AF',
      SIM: 'SIM x 1, PSAM x 1',
      Scanner: '1D & 2D swift scan (Optional)',
    },
    additionalContent: null,
    showInFooter: true,
    relatedProducts: ['sunmi-v2', 'sunmi-v2-pro', 'imin-swift-1', 'imin-m2-max'],
  },
];
