import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams, Link } from 'react-router-dom';

import { APP_URL, APP_NAME } from '../../constants/env';
import { PRODUCT_BRANDS, PRODUCT_CATEGORIES, PRODUCTS } from '../../constants/data';

export default function ProductBrand() {
  const location = useLocation();

  const { slug } = useParams();
  const productBrand = Object.values(PRODUCT_BRANDS).find((b) => b.slug === slug);

  const [productCategories, setProductCategories] = useState([]);

  useEffect(() => {
    const filteredData = Object.values(PRODUCT_CATEGORIES)
      .filter((c) => c.type === 'product')
      .map((c) => ({
        ...c,
        products: PRODUCTS.filter((p) => p.category === c.name && p.brand === productBrand.name),
      }))
      .filter((c) => c.products.length > 0);
    setProductCategories(filteredData);
  }, [productBrand]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={APP_URL} />
        <title>{`${productBrand.name} | ${APP_NAME}`}</title>
        <meta
          name="description"
          content={`Temukan produk terbaru dari ${productBrand.name} hanya di Solusi Bersama Informatika sebagai distributor resmi dari ${productBrand.name}`}
        />
        <meta
          name="keywords"
          content={`${productBrand.name.toLowerCase()}, mesin kasir, mesin kasir android, mesin kasir portable, mesin edc, mesin pembayaran, printer, printer struk, printer kasir, pdt, barcode, barcode scanner, sbi, solusi bersama informatika`}
        />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={APP_NAME} />
        <meta property="og:locale" content="id_ID" />
        <meta property="og:title" content={`${productBrand.name} | ${APP_NAME}`} />
        <meta
          property="og:description"
          content={`Temukan produk terbaru dari ${productBrand.name} hanya di Solusi Bersama Informatika sebagai distributor resmi dari ${productBrand.name}`}
        />
        <meta property="og:image" content={productBrand.image} />
        <meta property="og:image:alt" content={productBrand.name} />
        <meta property="og:url" content={`${APP_URL}${location.pathname}`} />

        <meta name="twitter:title" content={`${productBrand.name} | ${APP_NAME}`} />
        <meta
          name="twitter:description"
          content={`Temukan produk terbaru dari ${productBrand.name} hanya di Solusi Bersama Informatika sebagai distributor resmi dari ${productBrand.name}`}
        />
        <meta name="twitter:image" content={productBrand.image} />
        <meta name="twitter:image:alt" content={productBrand.name} />
      </Helmet>

      <section className="bg-white">
        <div className="mx-auto max-w-6xl px-8 py-10">
          <div className="mt-10 mb-20 flex flex-col items-center">
            <img className="w-auto h-16" src={productBrand.image} alt={productBrand.name} />
            <div className="mt-3">
              <h1 className="sr-only">{productBrand.name}</h1>
              <h2 className="px-10 lg:px-32 text-slate-700 text-lg text-center">
                {productBrand.description}
              </h2>
            </div>
          </div>

          {productCategories.map((category) => (
            <div key={category.slug} className="mb-20">
              <h4 className="text-xl font-bold leading-10">{category.name}</h4>
              <div className="mt-4 grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                {category.products.map((product) => (
                  <Link key={product.slug} to={`/product/${product.slug}`}>
                    <div className="group bg-slate-100 p-6 rounded-md cursor-pointer">
                      <img
                        className="w-full h-60 object-contain scale-90 group-hover:scale-100 ease-linear transition-all duration-150"
                        // eslint-disable-next-line global-require
                        src={require(`../../assets/images/products/${product.slug}/${product.image1}`)}
                        alt={product.name}
                      />
                      <h4 className="mt-4 text-center group-hover:text-blue-500 text-xl font-bold ease-linear transition-all duration-150">
                        {product.name}
                      </h4>
                      <h5 className="text-slate-400 text-center text-md font-bold">
                        {category.name}
                      </h5>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
