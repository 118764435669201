import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import { PRODUCT_CATEGORIES, PRODUCTS } from '../constants/data';

const appLogo = require('../assets/images/solusi-bersama-informatika.png');

export default function Footer() {
  const productCategories = Object.values(PRODUCT_CATEGORIES)
    .filter((c) => c.showInFooter)
    .map((c) => ({
      ...c,
      products: PRODUCTS.filter((p) => p.category === c.name && p.showInFooter),
    }));

  const productCategoryLabel = {
    'mobile-terminal': 'MOBILE DEVICE',
    'payment-terminal': 'PAYMENT DEVICE',
    'desktop-terminal': 'DESKTOP DEVICE',
    pdt: 'PDT / SCANNER',
  };

  return (
    <>
      <section className="bg-slate-100">
        <div className="mx-auto max-w-6xl px-6 py-10 lg:py-20">
          <div className="grid grid-cols-2 lg:grid-cols-6 gap-4">
            <div className="col-span-2 pb-10 lg:pb-0">
              <img className="h-12 w-auto" src={appLogo} alt="PT Solusi Bersama Informatika" />
              <div className="mt-6">
                Galeri Niaga Mediteranian 2 No. M8o
                <br />
                Jl. Pantai Indah Utara II, RT. 01 / RW. 16
                <br />
                Kapuk Muara, Penjaringan
                <br />
                DKI Jakarta 14460
              </div>
              <div className="mt-6">
                <strong>Phone:</strong> (021) 588 0997
                <br />
                <strong>Email:</strong> marketing@sbisolution.com
              </div>
            </div>

            {productCategories.map((category) => (
              <div key={category.slug}>
                <Link to={`/product/${category.slug}`}>
                  <h4 className="font-bold hover:text-blue-500 ease-linear transition-all duration-150">
                    {productCategoryLabel[category.slug]}
                  </h4>
                </Link>
                <div className="mt-4">
                  {category.products.map((product) => (
                    <div key={product.slug} className="group py-2 flex items-center">
                      <ChevronRightIcon className="h-3 w-3 mr-1 group-hover:ml-1 group-hover:text-blue-600 ease-linear transition-all duration-150" />
                      <Link to={`/product/${product.slug}`}>
                        <div className="group-hover:text-blue-500 group-hover:font-semibold ease-linear transition-all duration-150">
                          {product.name}
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="mx-auto max-w-6xl px-6 py-6">
          <div className="text-center text-sm">
            ©{new Date().getFullYear()} Copyright <strong>PT Solusi Bersama Informatika</strong>.
            All Rights Reserved.
          </div>
        </div>
      </section>
    </>
  );
}
