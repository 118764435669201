import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './assets/styles/app.css';
import ScrollToTop from './ScrollToTop';
import Views from './views';

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <Views />
  </BrowserRouter>,
  document.getElementById('root'),
);
