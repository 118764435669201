import React from 'react';
import AliceCarousel from 'react-alice-carousel';

const testimonialBackground = require('../../assets/images/testimonial-background.jpg');
const profilePicture = require('../../assets/images/testimonials/antonio-reynold.jpeg');

export default function TestimonialSection() {
  const testimonialItems = [0, 1, 2].map((testimonial) => (
    <div key={testimonial} className="px-24 py-2 flex flex-col items-center text-white">
      <img
        src={profilePicture}
        className="h-24 w-24 object-cover rounded-full border-4 border-white/25"
        alt=""
        data-ao="fade-up"
      />

      <h3 className="mt-4 text-xl font-bold" data-ao="fade-up" data-aos-delay="100">
        Antonio Reynold
      </h3>
      <h4 className="text-xl font-extralight" data-ao="fade-up" data-aos-delay="200">
        EMJI Coffee & Bar Manager
      </h4>

      <p
        className="mt-8 italic text-center leading-7 font-light"
        data-ao="fade-up"
        data-aos-delay="300">
        Untuk menjadi manager di EMJI Coffee Bar yang sudah memiliki cabang lebih dari satu sudah
        pasti ada kesulitannya, terlebih dalam saya mengontrol penjualan secara realtime, stock
        barang di outlet, dan juga customer yang mau menggunakan e-wallet sebagai alat pembayaran.
        Untungnya saya sekarang ga ribet lagi karena sudah menggunakan Point of Sales system dari
        SBI. Semuanya jadi mudah, praktis, dan ga pake ribet
      </p>
    </div>
  ));

  return (
    <>
      <section id="testimonial">
        <div
          className="bg-center bg-cover bg-no-repeat bg-fixed"
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${testimonialBackground})`,
          }}>
          <div className="mx-auto max-w-6xl px-6 py-20 text-white">
            <AliceCarousel
              disableButtonsControls
              disableDotsControls
              infinite
              mouseTracking
              items={testimonialItems}
            />
          </div>
        </div>
      </section>
    </>
  );
}
