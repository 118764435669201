import React from 'react';

const quotesBackground = require('../../assets/images/quotes-background.jpg');

export default function QuotesSection() {
  return (
    <>
      <section>
        <div
          className="bg-center bg-cover bg-no-repeat bg-fixed"
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${quotesBackground})`,
          }}>
          <div className="mx-auto max-w-6xl px-6 py-20 text-white">
            <h1 className="text-5xl font-thin" data-aos="fade-up">
              Because the people who are crazy enough to think they can change the world are the
              ones who do
            </h1>
            <h3 className="mt-6 text-2xl font-light" data-aos="fade-up" data-aos-delay="100">
              ~ Steve Jobs
            </h3>
          </div>
        </div>
      </section>
    </>
  );
}
