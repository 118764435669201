import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {
  ArrowRightIcon,
  ChevronDoubleRightIcon,
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  BuildingStorefrontIcon,
} from '@heroicons/react/24/outline';

const heroBackground = require('../../assets/images/hero-background.jpeg');

export default function HeroSection() {
  return (
    <>
      <section id="home" className="bg-white">
        <div
          className="bg-center bg-cover bg-no-repeat bg-fixed"
          style={{
            backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)), url(${heroBackground})`,
          }}>
          <div className="min-h-70-screen mx-auto max-w-6xl px-6 pt-28 text-white">
            <h1 className="text-5xl font-bold" data-aos="fade-up" data-aos-delay="100">
              SOLUSI BERSAMA INFORMATIKA
            </h1>
            <h2 className="mt-2 text-xl" data-aos="fade-up" data-aos-delay="200">
              Let the Technology solve your Business problems
            </h2>

            <div className="mt-10 flex items-center" data-aos="fade-up" data-aos-delay="300">
              <div className="h-16 w-16 z-10 bg-sky-500 rounded-full flex items-center justify-center drop-shadow">
                <ArrowRightIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <HashLink
                smooth
                to="/#about"
                className="-ml-8 py-3 pr-6 pl-12 bg-sky-700 hover:bg-sky-500 rounded-tr-full rounded-br-full ease-linear transition-all duration-150">
                <span className="text-xl">Browse Profile</span>
              </HashLink>
            </div>
          </div>
        </div>
        <div className="-mt-20 lg:-mt-36 min-h-70-screen mx-auto max-w-6xl px-6">
          <div className="lg:flex items-center">
            <div
              className="lg:w-1/3 p-8 bg-gradient-to-br from-sky-500 to-indigo-500 rounded text-white"
              data-aos="fade-up"
              data-aos-delay="400">
              <h3 className="text-4xl font-bold">
                Solusi Bersama Informatika as Your Business Partner?
              </h3>
              <p className="mt-6 text-lg">
                We'll listen to your business problems and needs, find the solution that fits you
                the most, and accelerate your company growth in this competitive era. We help you to
                open your mind about the potential of technology as your business solution.
              </p>
              <div className="mt-10 text-center">
                <HashLink
                  smooth
                  to="/#contact"
                  className="py-2 px-4 bg-white/25 hover:bg-sky-500 rounded-full ease-linear transition-all duration-150">
                  Contact Us
                  <ChevronDoubleRightIcon className="h-3 w-3 ml-2 inline" aria-hidden="true" />
                </HashLink>
              </div>
            </div>
            <div className="mt-10 lg:mt-0 lg:ml-6 flex-1 grid lg:gap-6 lg:grid-cols-3">
              <div
                className="mb-6 lg:mb-0 px-6 py-10 bg-white rounded-md drop-shadow-md text-center"
                data-aos="fade-up"
                data-aos-delay="500">
                <ComputerDesktopIcon
                  className="h-10 w-10 text-sky-500 mx-auto"
                  aria-hidden="true"
                />
                <h4 className="mt-8 text-xl font-bold leading-6">ENTERPRISE SOLUTION</h4>
                <p className="mt-8">
                  Develop any system and application according to your needs and operation
                  procedures
                </p>
              </div>
              <div
                className="mb-6 lg:mb-0 px-6 py-10 bg-white rounded-md drop-shadow-md text-center"
                data-aos="fade-up"
                data-aos-delay="600">
                <DevicePhoneMobileIcon
                  className="h-10 w-10 text-sky-500 mx-auto"
                  aria-hidden="true"
                />
                <h4 className="mt-12 text-xl font-bold leading-6">SMART DEVICES</h4>
                <p className="mt-10">
                  We provide any smart devices to simplify your business operation, like mobile
                  terminal, smart payment, etc.
                </p>
              </div>
              <div
                className="mb-6 lg:mb-0 px-6 py-10 bg-white rounded-md drop-shadow-md text-center"
                data-aos="fade-up"
                data-aos-delay="700">
                <BuildingStorefrontIcon
                  className="h-10 w-10 text-sky-500 mx-auto"
                  aria-hidden="true"
                />
                <h4 className="mt-8 text-xl font-bold leading-6">CLOUD-BASED POINT OF SALES</h4>
                <p className="mt-8">
                  Setup your store with our point of sales system that can connect to many smart
                  terminals
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
