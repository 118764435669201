import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import DevicePromotion from './DevicePromotion';
import AugustPromotion from './AugustPromotion';

export default function Page() {
  return (
    <>
      <Switch>
        <Route path="/page/device-promotion" exact component={DevicePromotion} />
        <Route path="/page/cicilan-grabmerchant" exact component={DevicePromotion} />
        <Route path="/page/promo-agustus" exact component={AugustPromotion} />
        <Redirect from="*" to="/" />
      </Switch>
    </>
  );
}
