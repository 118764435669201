import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import { useLocation } from 'react-router-dom';

import { APP_URL, APP_NAME } from '../../constants/env';

import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import ClientSection from './ClientSection';
import QuotesSection from './QuotesSection';
import ProductSection from './ProductSection';
import ProjectSection from './ProjectSection';
import TestimonialSection from './TestimonialSection';
import ContactSection from './ContactSection';

const ogImage = require('../../assets/images/og-image.jpg');

export default function Home() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={APP_URL} />
        <title>{APP_NAME} - Solusi Teknologi untuk Bisnis Anda</title>
        <meta
          name="description"
          content={`${APP_NAME} menyediakan solusi teknologi untuk bisnis Anda, seperti mesin kasir Android, terminal pembayaran, application development, dan lainnya. Temukan bagaimana solusi inovatif kami dapat mengoptimalkan operasional bisnis Anda`}
        />
        <meta
          name="keywords"
          content="mesin kasir, mesin kasir android, printer kasir, barcode, barcode scanner, sunmi, imin, idata, solusi teknologi, IT solution, teknologi bisnis, solusi inovatif"
        />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={APP_NAME} />
        <meta property="og:locale" content="id_ID" />
        <meta property="og:title" content={`${APP_NAME} - Solusi Teknologi untuk Bisnis Anda`} />
        <meta
          property="og:description"
          content="Solusi Bersama Informatika menyediakan solusi teknologi untuk bisnis Anda, seperti mesin kasir Android, terminal pembayaran, application development, dan lainnya. Temukan bagaimana solusi inovatif kami dapat mengoptimalkan operasional bisnis Anda"
        />
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:alt" content={APP_NAME} />
        <meta property="og:url" content={`${APP_URL}${location.pathname}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${APP_NAME} - Solusi Teknologi untuk Bisnis Anda`} />
        <meta
          name="twitter:description"
          content="Solusi Bersama Informatika menyediakan solusi teknologi untuk bisnis Anda, seperti mesin kasir Android, terminal pembayaran, application development, dan lainnya. Temukan bagaimana solusi inovatif kami dapat mengoptimalkan operasional bisnis Anda"
        />
        <meta name="twitter:image" content={ogImage} />
        <meta name="twitter:image:alt" content={APP_NAME} />
      </Helmet>

      <HeroSection />
      <AboutSection />
      <ClientSection />
      <QuotesSection />
      <ProductSection />
      <ProjectSection />
      <TestimonialSection />
      <ContactSection />
    </>
  );
}
