import React, { useRef, useEffect } from 'react';
import { MapPinIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';

export default function ContactSection() {
  const hubspotDivRef = useRef(null);

  useEffect(() => {
    const loadHubspotForm = () => {
      const formScript = document.createElement('script');
      formScript.async = true;
      formScript.innerHTML =
        'hbspt.forms.create({ region: "na1", portalId: "9105267", formId: "f58897ce-f624-4d09-8350-64dfb17d3b60" });';
      hubspotDivRef.current.appendChild(formScript);
    };

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.onload = () => loadHubspotForm();
    hubspotDivRef.current.appendChild(script);
  }, []);

  return (
    <>
      <section id="contact" className="bg-white">
        <div className="mx-auto max-w-6xl px-6 py-20">
          <div className="px-12 lg:px-32">
            <h4 className="mt-4 text-4xl text-center font-bold leading-10" data-aos="fade-up">
              Contact
            </h4>
            <p className="mt-4 text-lg text-center" data-aos="fade-up" data-aos-delay="100">
              Feel free to contact us, we will always listen to you and prepare some amazing
              solutions
            </p>
          </div>

          <div className="mt-12 px-0 lg:px-20 grid gap-8 grid-cols-1 md:grid-cols-2">
            <div>
              <div
                className="group mb-6 p-6 flex rounded-md shadow-lg"
                data-aos="fade-up"
                data-aos-delay="200">
                <div className="h-12 w-12 flex bg-white group-hover:bg-gradient-to-br from-sky-500 to-indigo-500 rounded-full items-center justify-center border border-sky-500 border-dashed ease-linear transition-all duration-150">
                  <MapPinIcon className="h-6 w-6 text-sky-500 group-hover:text-white ease-linear transition-all duration-150" />
                </div>
                <div className="mt-2 pl-6 flex-1">
                  <h4 className="text-xl font-bold">Our Address</h4>
                  <p className="mt-2">
                    Galeri Niaga Mediteranian 2 No. M8o
                    <br />
                    Jl. Pantai Indah Utara II, RT. 01 / RW. 16
                    <br />
                    Kapuk Muara, Penjaringan
                    <br />
                    DKI Jakarta 14460
                  </p>
                </div>
              </div>

              <div
                className="group mb-6 p-6 flex rounded-md shadow-lg"
                data-aos="fade-up"
                data-aos-delay="300">
                <div className="h-12 w-12 flex bg-white group-hover:bg-gradient-to-br from-sky-500 to-indigo-500 rounded-full items-center justify-center border border-sky-500 border-dashed ease-linear transition-all duration-150">
                  <EnvelopeIcon className="h-6 w-6 text-sky-500 group-hover:text-white ease-linear transition-all duration-150" />
                </div>
                <div className="mt-2 pl-6 flex-1">
                  <h4 className="text-xl font-bold">Email Us</h4>
                  <p className="mt-2">marketing@sbisolution.co.id</p>
                </div>
              </div>

              <div
                className="group mb-6 p-6 flex rounded-md shadow-lg"
                data-aos="fade-up"
                data-aos-delay="400">
                <div className="h-12 w-12 flex bg-white group-hover:bg-gradient-to-br from-sky-500 to-indigo-500 rounded-full items-center justify-center border border-sky-500 border-dashed ease-linear transition-all duration-150">
                  <PhoneIcon className="h-6 w-6 text-sky-500 group-hover:text-white ease-linear transition-all duration-150" />
                </div>
                <div className="mt-2 pl-6 flex-1">
                  <h4 className="text-xl font-bold">Call Us</h4>
                  <p className="mt-2">
                    +62 21 588 0996
                    <br />
                    +62 21 588 0997
                  </p>
                </div>
              </div>
            </div>
            <div
              className="p-8 pb-0 rounded-md shadow-lg"
              ref={(el) => (hubspotDivRef.current = el)}
              data-aos="fade-up"
              data-aos-delay="500"></div>
          </div>
        </div>
      </section>
    </>
  );
}
