import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, Switch, Route, Redirect } from 'react-router-dom';

import { APP_URL, APP_NAME } from '../constants/env';

import Page from './Page';

import Navbar from './Navbar';
import Footer from './Footer';

import Home from './Home';
import Product from './Product';

const appLogo = require('../assets/images/solusi-bersama-informatika.png');

export default function Views() {
  const location = useLocation();

  if (location.pathname.indexOf('/page') === 0) return <Page />;
  return (
    <>
      <Helmet>
        <link rel="canonical" href={APP_URL} />
        <title>{APP_NAME} - Solusi Teknologi untuk Bisnis Anda</title>
        <meta
          name="description"
          content={`${APP_NAME} menyediakan solusi teknologi untuk bisnis Anda, seperti mesin kasir Android, terminal pembayaran, application development, dan lainnya. Temukan bagaimana solusi inovatif kami dapat mengoptimalkan operasional bisnis Anda`}
        />
        <meta
          name="keywords"
          content="mesin kasir, mesin kasir android, printer kasir, barcode, barcode scanner, sunmi, imin, idata, solusi teknologi, IT solution, teknologi bisnis, solusi inovatif"
        />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={APP_NAME} />
        <meta property="og:locale" content="id_ID" />
        <meta property="og:title" content={`${APP_NAME} - Solusi Teknologi untuk Bisnis Anda`} />
        <meta
          property="og:description"
          content="Solusi Bersama Informatika menyediakan solusi teknologi untuk bisnis Anda, seperti mesin kasir Android, terminal pembayaran, application development, dan lainnya. Temukan bagaimana solusi inovatif kami dapat mengoptimalkan operasional bisnis Anda"
        />
        <meta property="og:image" content={appLogo} />
        <meta property="og:image:alt" content={APP_NAME} />
        <meta property="og:url" content={APP_URL} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${APP_NAME} - Solusi Teknologi untuk Bisnis Anda`} />
        <meta
          name="twitter:description"
          content="Solusi Bersama Informatika menyediakan solusi teknologi untuk bisnis Anda, seperti mesin kasir Android, terminal pembayaran, application development, dan lainnya. Temukan bagaimana solusi inovatif kami dapat mengoptimalkan operasional bisnis Anda"
        />
        <meta name="twitter:image" content={appLogo} />
        <meta name="twitter:image:alt" content={APP_NAME} />
      </Helmet>

      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/home" exact component={Home} />

        <Route path="/product" exact component={Product} />
        <Route path="/product/:slug" exact component={Product} />

        <Redirect from="*" to="/" />
      </Switch>
      <Footer />
    </>
  );
}
