import React from 'react';
import { useParams } from 'react-router-dom';

import ProductCategory from './ProductCategory';
import ProductBrand from './ProductBrand';
import ProductDetail from './ProductDetail';
import { PRODUCTS, PRODUCT_BRANDS } from '../../constants/data';

export default function Product() {
  const { slug } = useParams();

  if (PRODUCTS.find((p) => p.slug === slug)) return <ProductDetail />;
  if (Object.values(PRODUCT_BRANDS).find((b) => b.slug === slug)) return <ProductBrand />;
  return <ProductCategory />;
}
